/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.compose

import androidx.compose.runtime.*
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.LocalViewModelStoreOwner
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.ViewModelStore
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.ViewModelStoreOwner
import js.vue.App
import js.vue.onMounted
import js.vue.onUnmounted
import js.vuei18n.useI18n
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import org.jetbrains.compose.web.dom.DOMScope
import org.jetbrains.compose.web.dom.ElementScope
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement

val LocalVueComponentScope = staticCompositionLocalOf<CoroutineScope?> { null }

/**
 * This render a composable and hook CompositionLocalProviders with vue plugins
 */
fun <TElement : Element> renderVueComposable(
    root: TElement,
    content: @Composable DOMScope<TElement>.() -> Unit
): Composition {
    val i18n = useI18n()
    return renderComposable(root) {
        ProvidesVueI18n(i18n, content)
    }
}


private fun <TElement : Element> renderContentComposable(
    root: TElement,
    componentScope: CoroutineScope,
    viewModelStoreOwner: ViewModelStoreOwner,
    content: @Composable DOMScope<TElement>.() -> Unit
): Composition {
    return renderVueComposable(root) {
        CompositionLocalProvider(
            LocalVueComponentScope provides componentScope,
            LocalViewModelStoreOwner provides viewModelStoreOwner
        ) {
            content()
        }
    }
}


fun <TElement : Element> setContent(
    root: () -> TElement,
    content: @Composable DOMScope<TElement>.() -> Unit
) {
    val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    val viewModelStore = ViewModelStore()
    val viewModelStoreOwner = object : ViewModelStoreOwner {
        override val viewModelStore: ViewModelStore = viewModelStore
    }

    lateinit var composition: Composition
    onMounted {
        composition = renderContentComposable(
            root(),
            componentScope = coroutineScope,
            viewModelStoreOwner = viewModelStoreOwner,
            content = content
        )
    }
    onUnmounted {
        coroutineScope.cancel()
        composition.dispose()
    }
}


@Composable
fun ElementScope<HTMLElement>.UseVueEffect(
    key: Any?,
    content: () -> App
) {
    DisposableEffect(key) {
        val vueApp = content()
        vueApp.mount(scopeElement)
        onDispose {
            vueApp.unmount(scopeElement)
        }
    }
}
