/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages

import js.vue.ComponentOptions
import kotlinx.js.jso

val PrivacyPolicy: ComponentOptions = jso {
    template = """
        <div class="privacy-policy mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"></div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">


        <h3>Politique de confidentialité</h3>
            <p>
                Frédéric Barthéléry (« nous », « notre », « nos » ou « Bwè Manjé ») s’engagent à
                protéger la vie privée de tous les utilisateurs de notre site Web, <a href="https://bwemanje.app">bwemanje.app</a>, ou
                de nos applications mobiles (ensemble, les « Sites »). Veuillez lire la Politique de
                protection des données personnelles qui explique la manière dont nous utilisons et
                protégeons vos données. Sauf mention contraire, nous serons les « responsables de
                traitement » des données que vous nous communiquez.
            </p>
            <h4>1. Nous Contacter</h4>
            <p>
                Si vous avez des questions concernant notre Politique de protection des données
                personnelles ou, plus généralement, sur la façon dont nous traitons vos données,
                veuillez contacter :
            <ul>
                <li> notre service client à contact@bwemanje.app ;</li>
                <li>notre délégué à la protection des données personnelles à dpo@bwemanje.app</li>
            </ul>
            </p>

            <h4>2. Comment nous recueillons vos données</h4>
            <p>
                Nous recueillons vos données personnelles lorsque vous interagissez avec nous ou
                avec nos services, ou lorsque vous utilisez nos Sites pour passer une commande ou
                une réservation. Nous observons aussi la façon dont les visiteurs utilisent nos
                Sites, afin de nous aider à améliorer nos services et à optimiser notre expérience
                client.
                Nous recueillons des données :
            <ol>
                <li>lorsque vous créez un compte ou que vous changez les paramètres de ce compte ;
                </li>
                <li>lorsque vous passez une commande ou pendant le traitement de celle-ci (paiement
                    et
                    livraison) ;
                </li>
                <li>lorsque vous passez une réservation ou pendant le traitement de celle-ci;</li>
                <li>lorsque vous nous autorisez à vous contacter par email, téléphone, courrier
                    postal,
                    messagerie ou via notre chat en ligne pour vous envoyer le contenu de nos
                    campagnes
                    promotionnelles, ou pour vous inviter à participer à des enquêtes relatives à
                    nos
                    services ou à ceux de nos partenaires ;
                </li>
                <li>lorsque vous nous contactez directement par email, téléphone, courrier postal,
                    messagerie ou via notre chat en ligne, et
                </li>
                <li>lorsque vous naviguez et utilisez nos Sites (avant et après la création d’un
                    compte).
                </li>
            </ol>
            Nous recueillons aussi des informations auprès de sites tiers, tels que des
            plateformes publicitaires et notre prestataire de services de détection des fraudes.
            </p>

            <h4>3. Données que nous collectons</h4>
            <p>
                Dans le cadre de notre engagement à protéger les données de nos clients et, plus
                généralement, celles des visiteurs de nos Sites, nous souhaitons être transparents
                concernant les informations que nous collectons.
            </p><p>
                Lorsque vous visitez nos Sites ou que vous passez des commandes Bwè Manjé sur
                ceux-ci, nous vous demandons de fournir des informations vous concernant : votre
                nom, vos coordonnées, votre adresse de livraison, les détails de votre commande et
                les informations relatives à vos moyens de paiement.
            </p><p>
                Nous recueillons aussi des informations concernant l’utilisation que vous faites de
                nos Sites et lorsque vous publiez des messages sur nos Sites ou lorsque vous nous
                contactez ou que vous nous adressez des commentaires, par email, courrier postal,
                téléphone ou chat en ligne. Si vous nous contactez par téléphone, votre appel sera
                susceptible d’être enregistré à fins de formation et d’amélioration des services et
                nous prendrons des notes en relation avec votre appel.
            </p><p>
                Nous recueillons des informations techniques à partir de votre appareil mobile ou de
                votre ordinateur, par exemple sur son système d’exploitation, sur le type d’appareil
                et de connexion utilisés ainsi que l’adresse IP à partir desquels vous accédez à nos
                Sites.
            </p><p>
                Nous recueillons aussi des informations techniques sur votre utilisation de nos
                services par l’intermédiaire d’un appareil mobile, parmi lesquelles votre opérateur,
                des données de localisation et de performances telles que les modes de paiement
                mobile, les interactions avec d’autres technologies de distribution comme les tags
                NFC, les codes QR ou l’utilisation de tickets restaurant mobiles. À moins que vous
                ayez choisi de rester anonyme par l’intermédiaire de votre appareil ou des
                paramètres de notre plateforme, nous pouvons recueillir et utiliser automatiquement
                ces informations si vous utilisez notre service via votre/vos appareil(s) mobile(s)
                au moyen de toute application mobile Bwè Manjé, du navigateur de votre téléphone
                portable ou autrement.
            </p><p>
                Nous ne traitons des données de santé vous concernant que si vous y consentez et que
                vous nous les fournissez de vous-même, par exemple si vous précisez les allergies
                alimentaires dont vous souffrez.
            </p>

            <h4>4. Utilisation de vos données</h4>
            <p>
                Nous ne traiterons les données que nous recueillons à votre sujet que s’il y a un
                motif de le faire et que nous disposons d’une base légale pertinente pour vous
                fournir l’accès aux parties pertinentes des Sites ;
            <ul>
                <li>pour vous fournir les services que vous avez demandés ;</li>
                <li>pour pouvoir recueillir vos paiements, et</li>
                <li>pour vous contacter, lorsque cela est nécessaire à propos de nos services ; par
                    exemple pour régler d’éventuels problèmes liés à vos commandes.
                </li>
            </ul>
            <p>
                Nous traitons aussi vos données si nous disposons d’un intérêt légitime à le faire ;
                par
                exemple pour personnaliser notre service (traitement de données qui facilitent et
                accélèrent la procédure de commande). Nous avons répertorié ces raisons ci-dessous :
            </p>
            <ul>
                <li>améliorer l’efficacité et la qualité de service que nos clients peuvent attendre
                    de nous ;
                </li>
                <li>personnaliser le contenu que nous, nos partenaires restaurateurs ou
                    publicitaires vous proposons ; par exemple, pour vous proposer l’offre de
                    restaurants situés dans votre secteur ou pour nous assurer de vous proposer la
                    publicité la plus pertinente pour vous, basée sur des caractéristiques que nous
                    avons définies ;
                </li>
                <li>permettre à notre équipe d’assistance clients de vous aider le plus efficacement
                    possible si vous avez des questions ou des réclamations ;
                </li>
                <li>vous contacter pour connaître vos avis et commentaires sur nos produits et
                    services ou ceux de nos partenaires et pour vous informer en cas de changement
                    ou de développement importants affectant nos Sites ou nos services (vous
                    informer que nos services sont proposés dans un nouveau secteur, si vous avez
                    demandé à en être informé) ;
                </li>
                <li>vous envoyer des informations par courrier postal sur nos produits, services et
                    promotions (si vous ne souhaitez pas les recevoir, vous pouvez nous en informer
                    par le biais de la rubrique « Contact ») ;
                </li>
                <li>analyser votre activité sur nos Sites afin de gérer, soutenir, améliorer, ou
                    développer notre activité, à des fins statistiques et d’analyses, et pour nous
                    aider à empêcher les activités frauduleuses ;
                </li>
                <li>faire respecter le contrat qui nous lie ainsi que tout autre accord, pour
                    pouvoir exercer et protéger les droits de Bwè Manjé, des restaurants partenaires,
                    des coursiers ou autres (dont la prévention de la fraude).
                </li>
            </ul>
            <p>
                Si vous soumettez des commentaires concernant nos Sites et nos services, nous
                pouvons
                les utiliser sur nos Sites ainsi que sur tout support commercial ou publicitaire. À
                cette fin, nous ne vous identifierons que par votre prénom et par votre ville de
                résidence. Si vous avez choisi de recevoir nos notifications push à travers notre
                application mobile, nous pouvons vous en envoyer concernant les services que vous
                nous
                avez demandés ou encore pour vous donner des informations sur nos services et nos
                offres.
            </p>
            <p>
                Nous analysons également les données concernant votre utilisation de nos services à
                partir de vos données de localisation afin de vous créer un profil. Cela signifie
                que
                nous émettons certaines hypothèses sur ce qui peut vous intéresser et nous les
                utilisons, par exemple, pour vous envoyer des communications marketing adaptées,
                vous
                présenter des restaurants qui, selon nous, vous intéresseraient davantage, ou vous
                informer de nos offres spéciales ou de nos produits pouvant vous intéresser. Ce type
                de
                traitement est connu sous le nom de profilage. Vous disposez de certains droits
                quant à
                ce type de traitement. Veuillez vous reporter à la section « Vos droits » ci-dessous
                pour plus d’informations.
            </p>
            <p>
                Lorsque nous nous basons sur un intérêt légitime pour traiter vos données
                personnelles,
                nous effectuons un test « de mise en balance des intérêts » pour nous assurer que
                notre
                traitement est nécessaire et que vos droits fondamentaux en matière de protection de
                la
                vie privée ne l’emportent pas sur nos intérêts légitimes. Vous pouvez obtenir plus
                d’informations sur ces tests de mise en balance des intérêts en nous contactant au
                moyen
                des coordonnées ci-dessus.
            </p>
            <p>
                Lorsque nous y sommes légalement tenus, nous pouvons utiliser vos données pour créer
                un
                historique de vos commandes et pour respecter toute obligation légale ou exigence
                réglementaire auxquelles nous pouvons être soumis.
            </p>

            <h4>5. Cookies</h4>
            <p>
                Vous pouvez paramétrer votre navigateur pour refuser tous ou certains cookies, ou
                pour vous alerter lorsque des sites web installent ou accèdent à des cookies. Si
                vous désactivez ou refusez nos cookies, veuillez noter que certaines parties du Site
                peuvent devenir inaccessibles ou ne pas fonctionner correctement.
            </p>

            <h4>6. Marketing Direct</h4>
            <p>
                Si vous avez donné votre accord ou si nous disposons d’un intérêt légitime (et si la
                loi nous y autorise), nous utiliserons vos données pour vous présenter nos autres
                produits et services susceptibles de vous intéresser. Nous vous contacterons par
                email ou par téléphone.
            </p>

            <h4>7. Conservation de vos données</h4>
            <p>
            Nous ne conserverons pas vos données plus longtemps que nous le jugeons nécessaire.
            Les données que nous recueillons sont conservées aussi longtemps que nécessaire pour atteindre les objectifs décrits dans la section « Utilisation de vos données », conformément à notre intérêt légitime ou pour une durée spécifiquement requise par la réglementation en vigueur.
            Lors de la détermination des durées de conservation pertinentes, nous prenons en compte des facteurs tels que :
                <ul>
            <li>nos obligations et droits contractuels en relation avec les données considérées ;</li>
            <li>les obligations légales ou réglementaires de conservation des données ;</li>
            <li>les délais légaux de prescription applicables ;</li>
            <li>notre intérêt légitime lorsque nous avons effectué des tests de mise en balance (voir section intitulée « Utilisation de vos données personnelles ») ;</li>
            <li>les éventuels contentieux, et lignes directrices publiées par les autorités compétentes en matière de protection des données.</li>
        </ul>
            Nous supprimons vos données de façon sécurisée lorsque nous avons atteint l’objectif pour lequel elles ont été recueillies.
            </p>

            <h4>8. Partage de vos données</h4>
            <p>
                Les données que nous recueillons à votre sujet sont transférées et stockées sur nos
                serveurs situés dans l’Union européenne. Nous sommes très prudents et transparents
                en ce qui concerne les tiers avec qui vos informations sont partagées.
                Nous partageons vos données avec des prestataires de services tiers qui assurent des
                prestations de service pour notre compte. Les prestataires de services tiers avec
                lesquels nous partageons vos informations comprennent :
            <ul>
                <li>les prestataires de services de paiement (dont les prestataires de services de
                    paiement en ligne et les prestataires de services de détection de la fraude) ;
                </li>
                <li>les prestataires de services informatiques (dont les prestataires de services
                    cloud) ;
                </li>
                <li>les restaurants partenaires ;</li>
                <li>les coursiers ;</li>
                <li>les partenaires d’assistance clients ;</li>
                <li>les partenaires marketing et publicitaires.</li>
            </ul>
            </p>
            <p>
                BwèManjé prend toutes les mesures raisonnablement nécessaires pour que vos données
                soient traitées en toute sécurité et conformément à la présente Politique de
                protection des données lorsqu’elles sont communiquées à des tiers.
                Si notre entreprise conclut un accord de « joint-venture » avec une autre entité
                commerciale, achète une autre entité commerciale ou est cédée à ou fusionnée à une
                autre entité commerciale, vos données peuvent être divulguées ou transférées à cette
                entité commerciale, à nos nouveaux partenaires commerciaux ou propriétaires ou à
                leurs conseils.
                Nous pouvons également partager vos données :
            <ul>
                <li>si nous sommes dans l’obligation légale ou réglementaire de divulguer ou de
                    partager vos données. Ceci inclut l’échange d’informations avec d’autres
                    sociétés et organisations à des fins de détection et de protection contre la
                    fraude ;
                </li>
                <li>afin de mettre en oeuvre le contrat ou tout autre accord nous liant ;</li>
                <li>afin de protéger les droits de Bwè Manjé, ceux des restaurants partenaires, des
                    coursiers ou d’autres parties, et de prévenir la fraude, et
                    avec les tiers dont nous pensons que la participation est nécessaire afin de
                    détecter ou de prévenir les activités délictuelles, par exemple avec les
                    services de police.
                </li>
            </ul>

            <h5>Transferts internationaux de données </h5>
            <p>
                Dans certains cas, les données personnelles que nous recueillons auprès de vous
                peuvent être traitées en dehors de l’Espace économique européen (« EEE »), par
                exemple aux États-Unis, à Madagascar et dans les pays dans lesquels Bwè Manjé opère.
                Il se peut que ces pays n’aient pas le même niveau de protection des données
                personnelles que ceux de l’EEE. Toutefois, nous sommes tenus de veiller à ce que les
                données personnelles que nous et nos fournisseurs traitons en dehors de l’EEE soient
                protégées de la même manière qu’elles le seraient si elles étaient traitées dans
                l’EEE. Certaines garanties sont donc mises en place lorsque vos données sont
                traitées en dehors de l’EEE.
                Nous faisons en sorte qu’elles bénéficient d’un degré de protection similaire en
                mettant en œuvre au moins l’une des mesures de protection suivantes :
            <ul>
                <li>vos données personnelles sont transférées vers des pays qui ont été considérés
                    comme assurant un niveau de protection adéquat des données personnelles par la
                    Commission européenne ;
                </li>
                <li>nous utilisons les Clauses Contractuelles Types approuvées par l’UE, et</li>
                <li>si vos données personnelles sont transférées à des tiers prestataires de service
                    basés aux États-Unis, les données peuvent leur être transférées s’ils ont été
                    auto-certifiés dans le cadre du Privacy Shield en relation avec le type de
                    données transférées, ce qui leur impose d’assurer une protection similaire aux
                    données personnelles partagées entre l’UE et les États-Unis.
                </li>
            </ul>
            <p>
            Veuillez nous contacter si vous souhaitez obtenir de plus amples informations sur les
            pays vers lesquels des données personnelles peuvent être transférées et sur le mécanisme
            spécifique que nous utilisons lors du transfert de vos données personnelles en dehors de
            l’EEE.
            </p>

            <h4>9. Sécurité</h4>
            <p>
                Nous adoptons des technologies et des politiques solides pour nous assurer que les
                données personnelles que nous détenons à votre sujet sont adéquatement protégées.
            </p><p>
                Nous prenons des mesures afin de protéger vos données contre l’accès non autorisé et
                contre le traitement illicite, la perte accidentelle, la destruction et contre tout
                autre dommage.
            </p><p>
                Si vous avez choisi un mot de passe qui vous permet d’accéder à certaines parties du
                Site, vous êtes responsable de la confidentialité de ce mot de passe. Nous vous
                recommandons de ne partager votre mot de passe avec aucune personne.
            </p><p>
                Malheureusement, la transmission de données par Internet n’est pas complètement
                sûre. Nous prenons des mesures pour protéger vos données mais nous ne pouvons pas
                garantir la sécurité des données que vous nous transmettez ; toute transmission de
                données se fait donc à vos propres risques. Une fois que nous recevons vos données,
                nous utilisons des procédures strictes et des dispositifs de sécurité pour essayer
                d’empêcher tout accès non autorisé à celles-ci.
            </p>

            <h4>10. Vos droits</h4>
            <p>
                Sous réserve des dispositions de la loi applicable, vous disposez d’un certain
                nombre de droits concernant les données que nous détenons à votre sujet. Si vous
                souhaitez exercer l’un de ces droits, veuillez contacter notre délégué à la
                protection des données. Pour en savoir plus sur vos droits, veuillez contacter
                l’autorité de protection des données dont vous relevez.
            </p><p>
                Dans la mesure où la loi le prévoit dans votre juridiction, vous pouvez demander à
                avoir accès aux données personnelles que nous conservons à votre sujet ou demander à
                ce qu’elles soient corrigées, mises à jour, modifiées ou supprimées ou que nous
                limitions le traitement de ces données en nous contactant selon les modalités
                indiquées ci-dessous.
            </p><p>
                Vous pouvez être en droit d’obtenir vos données personnelles dans un format
                accessible et transférable afin de pouvoir les réutiliser à vos propres fins auprès
                de différents prestataires de services.
            </p><p>
                Si la loi le prévoit, vous pouvez retirer le consentement que vous avez exprimé
                antérieurement ou contester à tout moment, pour des motifs légitimes, le traitement
                de vos données personnelles et, le cas échéant, nous prendrons en compte ce retrait.
                Ceci n’affecte pas la légalité de l’utilisation de notre part de vos informations
                personnelles sur la base de votre consentement avant son retrait.
                Vous pouvez vous opposer au traitement de vos données personnelles en nous
                contactant (voir section « Nous Contacter »).
            </p>

            <h4>11. Changements affectant notre Politique de protection des données
                personnelles</h4>
            <p>
                Tout changement affectant notre Politique de protection des données personnelles
                sera publié sur les Sites et, le cas échéant, nous vous informerons de ces
                changements, par exemple par email ou au moyen d’une notification push.
            </p>
            <p>
                Dernière mise à jour de notre Politique de protection des données personnelles : 07
                novembre 2021
            </p>

            <h4>12. Réclamations</h4>
            <p>
                Si vous n’êtes pas satisfait de nos réponses à vos réclamations, ou si vous
                considérez que le traitement des données que nous effectuons n’est pas conforme à la
                loi relative à la protection des données personnelles, vous pouvez adresser une
                réclamation par email à dpo@bwemanje.app
            </p>




            </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"></div>
        </div>
        </div>
    """.trimIndent()
}
