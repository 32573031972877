/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package js.vuerouter

import js.vue.ComponentOptions
import kotlinx.js.jso
import kotlin.js.json

typealias NavigationGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized) -> dynamic

fun RouterOptions.routes(builder: RoutesBuilder.() -> Unit) {
    routes = RoutesBuilder().apply(builder).build()
}

@DslMarker
annotation class RoutesBuilderDsl

@RoutesBuilderDsl
class RoutesBuilder {
    private val routes = mutableListOf<RouteRecordRaw>()
    internal fun build() : Array<RouteRecordRaw> {
        return routes.toTypedArray()
    }

    fun singleViewRecord(component: ComponentOptions, builder: RouteRecordRaw.() -> Unit) {
        val route = jso<RouteRecordSingleView> {
            this.component = component
            apply(builder)
        }
        routes.add(route)
    }
}

@Suppress("DEPRECATION")
fun RouteRecordRaw.passRouteParamsAsProp() {
    props = true
}

@Suppress("DEPRECATION")
fun RouteRecordRaw.props(vararg pairs: Pair<String, Any>) {
    props = json(*pairs)
}

@Suppress("DEPRECATION")
fun RouteRecordRaw.meta(vararg pairs: Pair<String, Any>) {
    meta = json(*pairs)
}

