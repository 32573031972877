/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.locales

import js.vuei18n.createI18n
import kotlinx.js.Object
import kotlinx.browser.document
import kotlinx.browser.window
import kotlin.js.json

object I18n {
    private val messages = json(
        "en" to enMessages,
        "fr" to frMessages
    )

    // actually not used because it doesn't allow to display another price based
    // on locale
    private val numberFormats = json(
        "en" to json(
            "currency" to json(
                "style" to "currency",
                "currency" to "USD"
            )
        ),
        "fr" to json(
            "currency" to json(
                "style" to "currency",
                "currency" to "EUR"
            )
        )
    )

    /**
     * https://vue-i18n-next.intlify.dev/essentials/datetime.html
     */
    private val dateTimeFormats = json(
        "en" to enDateTimeFormats,
        "fr" to frDateTimeFormats
    )

    val availableLocales = Object.keys(messages)

    /**
     * The vue-i18n plugin
     */
    var plugin: dynamic = null

    fun setupI18n(): dynamic {
        val i18n = createI18n(json(
            "legacy"  to false,
            "locale" to getBrowserLocale(),
            "fallbackLocale" to "en",
            "messages" to messages,
            "numberFormats" to numberFormats,
            "datetimeFormats" to dateTimeFormats
        ))
        plugin = i18n
        return plugin
    }

    fun setLanguage(locale: String) {
        plugin.global.locale.value = locale
        document.querySelector("html")?.setAttribute("lang", locale)
    }
}

private fun getBrowserLocale(): String = try {
    val availableLangs = window.navigator.languages.map {
        it.split("-").first()
    }.distinct()
    availableLangs.firstOrNull {
        it in I18n.availableLocales
    } ?: "en"
} catch (e: Throwable) {
    "en"
}
