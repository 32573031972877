/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal

import com.geekorum.rdv.bwemanje.customerportal.pages.PrivacyPolicy
import com.geekorum.rdv.bwemanje.customerportal.pages.SignInPage
import com.geekorum.rdv.bwemanje.customerportal.pages.TermsOfService
import com.geekorum.rdv.bwemanje.customerportal.pages.portalpage.PaymentOnBoardingPage
import com.geekorum.rdv.bwemanje.customerportal.pages.portalpage.PortalPage
import firebase.app.FirebaseApp
import firebase.auth
import firebase.auth.Auth
import js.vuerouter.RouteLocationNormalized
import js.vuerouter.Router
import js.vuerouter.createRouter
import js.vuerouter.createWebHistory
import js.vuerouter.meta
import js.vuerouter.props
import js.vuerouter.routes
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.promise
import kotlinx.js.jso

class AppRouter(
    private val firebaseApp: FirebaseApp
) {

    val router: Router by lazy {
        createRouter(jso {
            // Routes that can be accessed directly by taping the url in browser or by
            // external components need to be declared in firebase.json
            routes {
                singleViewRecord(PortalPage) {
                    path = "/"
                    meta("requiresAuth" to true)
                }
                singleViewRecord(PaymentOnBoardingPage) {
                    path = "/onboarding_payment"
                    meta("requiresAuth" to true)
                }
                singleViewRecord(SignInPage) {
                    path = "/signin"
                    props("signedInPath" to "/")
                }
                singleViewRecord(TermsOfService) {
                    path = "/terms-of-service"
                }
                singleViewRecord(PrivacyPolicy) {
                    path = "/privacy-policy"
                }
            }
            history = createWebHistory()
        })
    }

    init {
        router.beforeEach { to, _ ->
            GlobalScope.promise {
                if (isPrivateRoute(to)) {
                    val firebaseAuth = firebaseApp.auth()
                    // wait for onAuthStateChanged to be called once
                    val currentUser = firebaseAuth.currentUserFlow().first()
                    return@promise currentUser?.let { true } ?: "/signin"
                } else true
            }
        }
    }

    private fun isPrivateRoute(route: RouteLocationNormalized): Boolean {
        return route.meta.requiresAuth == true
    }

}

private fun Auth.currentUserFlow() = callbackFlow {
    val unsubscribe = onAuthStateChanged { user ->
        trySend(user)
    }
    awaitClose {
        unsubscribe()
    }
}
