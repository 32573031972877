/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal

import com.geekorum.rdv.bwemanje.customerportal.components.AppFooter
import com.geekorum.rdv.bwemanje.customerportal.components.AppHeader
import com.geekorum.rdv.bwemanje.customerportal.components.MdcCircularProgress
import com.geekorum.rdv.bwemanje.customerportal.locales.I18n
import firebase.app.initializeApp
import firebase.auth
import firebase.auth.User
import js.`this`
import js.thisAs
import js.vue.ComponentOptions
import js.vue.computed
import js.vue.createApp
import js.vue.provides
import js.vuerouter.Router
import kotlinx.js.jso
import kotlin.js.json

val STRIPE_API_KEY = js("process.env.STRIPE_API_KEY")

private val FIREBASE_CONFIG = js("process.env.FIREBASE_CONFIG")
val DEPLOYMENT_ENV = js("process.env.DEPLOYMENT_ENVIRONMENT")
val FIREBASE_FUNCTIONS_REGION = js("process.env.FIREBASE_FUNCTIONS_REGION")
val FIREBASE_FUNCTIONS_REGION_STRIPE_EXT = js("process.env.FIREBASE_FUNCTIONS_REGION_STRIPE_EXT")

fun main() {
    val firebaseApp = initializeApp(FIREBASE_CONFIG)
    kotlinext.js.require("firebase/functions")
    kotlinext.js.require("firebase/firestore")

    val appRouter = AppRouter(firebaseApp)

    val rootComponent = jso<ComponentOptions> {
        data = { RootState() }
        provides {
            val state = thisAs<RootState>()
            json("firebaseApp" to firebaseApp,
                // retrieve the state when reading .value
                "firebaseUser" to computed { state.firebaseUser },
            )
        }
        created = {
            val firebaseauth = firebaseApp.auth()
            val state = thisAs<RootState>()
            firebaseauth.onAuthStateChanged { user ->
                state.firebaseUser = user
            }
        }
    }

    val i18n = I18n.setupI18n()
    val vueApp = createApp(rootComponent)
        .use(appRouter.router)
        .use(i18n)
        .component("app-header", AppHeader)
        .component("app-footer", AppFooter)
    vueApp.mount("#root")
}

@OptIn(ExperimentalJsExport::class)
@JsExport
class RootState(
    var firebaseUser: User? = jso(),
)

