/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.components

import com.geekorum.rdv.bwemanje.customerportal.locales.I18n
import js.vue.*
import js.vuei18n.useI18n
import kotlinx.js.jso
import org.w3c.dom.Element
import kotlin.js.json

val AppFooter = jso<ComponentOptions> {
    template = """
        <footer>
            <p v-t="'global.footer'"></p>
        </footer>
    """.trimIndent()
}
