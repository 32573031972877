/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages.portalpage

import com.geekorum.rdv.bwemanje.customerportal.STRIPE_API_KEY
import com.geekorum.rdv.bwemanje.customerportal.components.FUNCTION_STRIPE_CREATE_ACCOUNT_EXPRESS_LINK
import com.geekorum.rdv.bwemanje.customerportal.components.FUNCTION_STRIPE_CREATE_PORTAL_LINK
import firebase.firestore.DocumentReference
import firebase.firestore.Firestore
import firebase.firestore.addDoc
import firebase.firestore.collection
import firebase.firestore.doc
import firebase.firestore.get
import firebase.firestore.onSnapshot
import firebase.firestore.orderBy
import firebase.firestore.where
import firebase.functions.Functions
import firebase.functions.httpsCallable
import firebase.functions.invoke
import js.stripe.loadStripe
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.await
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapLatest
import kotlinx.coroutines.flow.stateIn
import kotlinx.coroutines.launch
import org.w3c.xhr.FormData
import kotlin.js.Date
import kotlin.js.json

@OptIn(ExperimentalCoroutinesApi::class)
internal class PortalPageViewModel(
    private val firestore: Firestore,
    private val functions: Functions,
    private val stripeExtFunctions: Functions,
    private val viewModelScope: CoroutineScope,
    val showTestCards: Boolean = false
) {

    internal data class UiState(
        val userId: String? = null,
        val subscriptions: List<Subscription> = emptyList(),
        val subscriptionPlans: List<Product> = emptyList(),
        val isLoading: Boolean = true,
        val hasActiveSubscription: Boolean = false,
        val hasCompletePaymentOnBoarding: Boolean = false,
        val showTestCards: Boolean = false,
    )

    private val userId = MutableStateFlow<String?>(null)

    private val subscriptions = userId.filterNotNull().mapLatest {
        getActiveSubscriptionsList(it)
    }

    private val subscriptionPlans = flow {
        val res = getProductsList()
        emit(res)
    }

    private val isLoading = MutableStateFlow(false)

    private val hasActiveSubscription = subscriptions.map { it.isNotEmpty() }

    private val hasCompletePaymentOnboarding = userId.filterNotNull().map {
        hasCompletePaymentOnboarding(it)
    }

    @Suppress("UNCHECKED_CAST")
    val uiState = combine(userId, subscriptions, subscriptionPlans, isLoading, hasActiveSubscription, hasCompletePaymentOnboarding) {
        UiState(
            userId = it[0] as String?,
            subscriptions = it[1] as List<Subscription>,
            subscriptionPlans = it[2] as List<Product>,
            isLoading = it[3] as Boolean,
            hasActiveSubscription = it[4] as Boolean,
            hasCompletePaymentOnBoarding = it[5] as Boolean,
            showTestCards = showTestCards,
        )
    }.stateIn(viewModelScope, SharingStarted.WhileSubscribed(5000), UiState())

    fun setUserId(user: String) {
        userId.value = user
    }

    fun subscribe(
        formData: FormData,
        onCheckoutSessionFailed: () -> Unit,
    ) = viewModelScope.launch {
        try {
            isLoading.value = true
            val price = formData.get("price")
            val quantity = formData.get("quantity")
            val sessionId = createCheckoutSession(userId.value!!, price, quantity)
            if (sessionId == null) {
                onCheckoutSessionFailed()
            } else {
                redirectToStripeCheckout(sessionId)
            }
        } finally {
            isLoading.value = false
        }
    }

    fun openStripeExpressDashboard() {
        onRedirectStart()
        val newWindow = window.open("", "stripe_express") ?: window
        viewModelScope.launch {
            try {
                val result =
                    httpsCallable<dynamic, dynamic>(
                        functions,
                        FUNCTION_STRIPE_CREATE_ACCOUNT_EXPRESS_LINK
                    )().await()
                newWindow.location.replace(result.data.url)
            } finally {
                onRedirectEnd()
            }

        }
    }

    fun openStripeBillingPortal() {
        onRedirectStart()
        viewModelScope.launch {
            try {
                val returnUrl = window.location.href
                val result =
                    httpsCallable<dynamic, dynamic>(
                        stripeExtFunctions,
                        FUNCTION_STRIPE_CREATE_PORTAL_LINK
                    )(
                        json(
                            "returnUrl" to returnUrl
                        )
                    ).await()
                window.location.assign(result.data.url)
            } finally {
                onRedirectStart()
            }
        }
    }

    private fun onRedirectStart() {
        isLoading.value = true
    }

    private fun onRedirectEnd() {
        isLoading.value = false
    }

    private suspend fun getActiveSubscriptionsList(userId: String): List<Subscription> {
        val snapshot = firestore.collection<dynamic>("customers")
            .doc(userId)
            .collection<dynamic>("subscriptions")
            .where("status", "in", arrayOf("trialing", "active"))
            .get()
            .await()
        return snapshot.docs.map {
            val id = it.id
            val subscription = it.data()

            // workaround priceRef not having get() method. I suppose it's because of bug
            // https://firebase.google.com/support/release-notes/js fixed in 8.0.2 but it still happens
            val priceRef = firestore.doc<dynamic>(subscription.price.path)
            val price = getPrice(priceRef)
            val trialEnd = if (subscription.trial_end != null)
                subscription.trial_end.toDate()
            else null
            Subscription(
                id, price,
                currentPeriodEnd = subscription.current_period_end.toDate(),
                trialEnd = trialEnd,
            )
        }
    }

    private suspend fun getPrice(priceRef: DocumentReference<dynamic>): Price {
        val pricesSnapshot = priceRef.get()
            .await()
        val data = pricesSnapshot.data()
        return Price(
            pricesSnapshot.id,
            data.currency,
            data.description,
            data.interval,
            data.unit_amount
        )
    }

    private suspend fun getProductsList(): List<Product> {
        val snapshot = firestore.collection<dynamic>("stripe/data/products")
            .where("active", "==", true)
            .get()
            .await()
        return snapshot.docs.map {
            val id = it.id
            val data = it.data()
            val prices = getPrices(it.ref)
            Product(id, data.name, data.description, prices)
        }
    }

    private suspend fun getPrices(productRef: DocumentReference<*>): Array<Price> {
        val pricesSnapshot = productRef.collection<dynamic>("prices")
            .orderBy("unit_amount").get()
            .await()

        return pricesSnapshot.docs.map {
            val data = it.data()
            Price(
                it.id,
                data.currency,
                data.description,
                data.interval,
                data.unit_amount,
                active = data.active
            )
        }.toTypedArray()
    }

    private suspend fun hasCompletePaymentOnboarding(userId: String): Boolean {
        val customerDocSnapshot = firestore.collection<dynamic>("customers")
            .doc(userId)
            .get()
            .await()
        return if (customerDocSnapshot.exists()) {
            val customer = customerDocSnapshot.data()
            return customer.hasSendBusinessInformation.unsafeCast<Boolean>() &&
                    customer.stripeChargesEnabled.unsafeCast<Boolean>() &&
                    customer.stripeDetailsSubmitted.unsafeCast<Boolean>() &&
                    customer.stripeAccountId.unsafeCast<String>().isNotBlank()
        } else false
    }

    private suspend fun createCheckoutSession(
        userId: String,
        priceId: String, quantity: Int = 1
    ): String? {
        val customerDocSnapshot = firestore.collection<dynamic>("customers")
            .doc(userId)
            .get()
            .await()
        val hadPreviousTrial = if (customerDocSnapshot.exists())
            customerDocSnapshot.data()._hadTrial.unsafeCast<Boolean?>() ?: false
        else false
        val colRef = collection<dynamic>(firestore, "customers", userId, "checkout_sessions")
        val docRef = addDoc(
            colRef,
            json(
                "price" to priceId,
                "allow_promotion_codes" to true,
                "trial_from_plan" to !hadPreviousTrial,
//            "tax_rates" to taxRatesId,
                "success_url" to window.location.origin,
                "cancel_url" to window.location.origin,
                "line_items" to arrayOf(
                    json(
                        "price" to priceId,
                        "quantity" to quantity,
                    )
                )
            )
        ).await()

        data class SessionResult(
            val sessionId: String? = null,
            val error: dynamic = null
        )

        // we need to wait for the sessionId and/or error to be attached by the cloud function
        val resultFlow = callbackFlow {
            docRef.onSnapshot(onNext = { snapshot ->
                val data = snapshot.data()
                val error = data.error
                val sessionId = data.sessionId
                if (error != null) {
                    trySend(SessionResult(error = error))
                } else if (sessionId != null) {
                    trySend(SessionResult(sessionId = sessionId))
                }
            })
            awaitClose()
        }
        val result = resultFlow.first()
        return result.sessionId
    }

    private suspend fun redirectToStripeCheckout(sessionId: String) {
        val stripe = loadStripe(STRIPE_API_KEY).await()
        stripe.redirectToCheckout(json("sessionId" to sessionId))
    }


    internal data class Subscription(
        val id: String,
        val price: Price,
        val currentPeriodEnd: Date? = null,
        val trialEnd: Date? = null
        // TODO when user cancels, the subscription is still active
        //  but has some fields: cancel_at: Date, cancel_at_period_end: Boolean
        // we should print a message that says the plan is cancelled and will end at
    )

}


/* Firestore model classes */
// TODO create Firestore converters and move these types in :data-firestore

external interface Price {
    val id: String
    val currency: String
    val description: String
    val interval: String
    val unit_amount: Int
    val active: Boolean
}

val Price.amount: Double
    get() = unit_amount / 100.0

val Price.currencySymbol: String
    get() = when (currency) {
        "usd" -> "$"
        "eur" -> "€"
        else -> currency
    }

fun Price(
    id: String,
    currency: String = "",
    description: String = "",
    interval: String = "",
    unit_amount: Int = 0,
    active: Boolean = false,
): Price = kotlinext.js.js {
    this.id = id
    this.currency = currency
    this.description = description
    this.interval = interval
    this.unit_amount = unit_amount
    this.active = active
}.unsafeCast<Price>()



external interface Product {
    val id: String
    val name: String
    val description: String
    val prices: Array<Price>
}

fun Product(
    id: String = "",
    name: String = "",
    description: String = "",
    prices: Array<Price> = emptyArray()
) = kotlinext.js.js {
    this.id = id
    this.name = name
    this.description = description
    this.prices = prices
}.unsafeCast<Product>()

