/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package firebase

import firebase.app.FirebaseApp
import firebase.auth.getAuth
import firebase.firestore.getFirestore
import firebase.functions.getFunctions

fun FirebaseApp.auth() = getAuth(this)
fun FirebaseApp.firestore() = getFirestore(this)
fun FirebaseApp.functions() = getFunctions(this)
fun FirebaseApp.functions(regionOrCustomDomain: String) = getFunctions(this, regionOrCustomDomain)
