/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package firebase.firestore

import kotlin.js.Promise

/*@Suppress("NOTHING_TO_INLINE")
inline operator fun DocumentData.get(field: String): Any? = asDynamic()[field]

@Suppress("NOTHING_TO_INLINE")
inline operator fun DocumentData.set(field: String, value: Any) {
    asDynamic()[field] = value
}*/


fun <T> Firestore.collection(collectionPath: String): CollectionReference<T> =
    collection(this, collectionPath)

fun <T> Firestore.doc(documentPath: String): DocumentReference<T> =
    doc(this, documentPath)


fun <T> DocumentReference<*>.collection(collectionPath: String): CollectionReference<T> =
    collection(parent, id, collectionPath)

fun <T> DocumentReference<T>.get(): Promise<DocumentSnapshot<T>> =
    getDoc(this)

fun <T> DocumentReference<T>.onSnapshot(
    onNext: (snapshot: DocumentSnapshot<T>) -> Unit,
    onError: ((error: Error) -> Unit)? = null,
): Unsubscribe =
    onSnapshot(this, onNext, onError)

fun <T> DocumentReference<T>.set(data: Any): Promise<Unit> =
    setDoc(this, data)

fun <T> DocumentReference<T>.set(data: Any, options: SetOptions): Promise<Unit> =
    setDoc(this, data, options)

//open fun update(data: Map<String, Any?>): Promise<Unit>
//open fun update(field: String, value: Any, vararg moreFieldsAndValues: Any): Promise<Unit>
//open fun update(field: FieldPath, value: Any, vararg moreFieldsAndValues: Any): Promise<Unit>
//open fun delete(): Promise<Unit>

fun <T> Query<T>.orderBy(fieldPath: String, directionStr: String): Query<T> =
    query(this, firebase.firestore.orderBy(fieldPath, directionStr))

fun <T> Query<T>.orderBy(fieldPath: String): Query<T> =
    query(this, firebase.firestore.orderBy(fieldPath))

fun <T> Query<T>.where(
    fieldPath: String,
    opStr: String /* '<' | '<=' | '==' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' */,
    value: Any
): Query<T> = query(this,
    firebase.firestore.where(fieldPath, opStr, value)
)

fun <T> Query<T>.get(): Promise<QuerySnapshot<T>> = getDocs(this)

fun <T> CollectionReference<T>.doc(documentPath: String): DocumentReference<T> =
    doc(firestore, path, documentPath)

