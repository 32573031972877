/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.compose

import androidx.compose.runtime.*
import js.vue.watchEffect
import org.jetbrains.compose.web.dom.DOMScope
import org.w3c.dom.Element
import kotlin.js.Date
import kotlin.js.Json
import kotlin.js.json

/**
 * Access VueI18n module
 */
val LocalVueI18n = staticCompositionLocalOf<Any?> { null }

@Composable
fun <TElement : Element> DOMScope<TElement>.ProvidesVueI18n(
    i18n: Any?,
    content: @Composable (DOMScope<TElement>.() -> Unit)
) {
    CompositionLocalProvider(LocalVueI18n provides i18n) {
        content()
    }
}

@Composable
fun stringResource(key: String): String {
    val i18n = LocalVueI18n.current.asDynamic()
    check(i18n != undefined) { "i18n is not set"}

    val result by produceState(initialValue = i18n.t(key)) {
        val stopEffect = watchEffect {
            value = i18n.t(key)
        }
        awaitDispose {
            stopEffect()
        }
    }
    return result as String
}


@Composable
fun stringResource(key: String, namedArgs: Map<String, Any>): String {
    val namedArgsJson = json(*namedArgs.entries.map{ it.key to it.value}.toTypedArray())
    return stringResource(key, namedArgsJson)
}

@Composable
fun stringResource(key: String, namedArgs: Json): String {
    val i18n = LocalVueI18n.current.asDynamic()
    check(i18n != undefined) { "i18n is not set"}

    val result by produceState(initialValue = i18n.t(key, namedArgs)) {
        val stopEffect = watchEffect {
            value = i18n.t(key, namedArgs)
        }
        awaitDispose {
            stopEffect()
        }
    }
    return result as String
}

@Composable
fun dateFormat(date: Date, formatName: String): String {
    val i18n = LocalVueI18n.current.asDynamic()
    check(i18n != undefined) { "i18n is not set"}

    val result by produceState(initialValue = i18n.d(date, formatName)) {
        val stopEffect = watchEffect {
            value = i18n.d(date, formatName)
        }
        awaitDispose {
            stopEffect()
        }
    }
    return result as String
}

