/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages

import js.vue.ComponentOptions
import kotlinx.js.jso

val TermsOfService: ComponentOptions = jso {
    template = """
<div class="terms-of-service mdc-layout-grid">
        <div class="mdc-layout-grid__inner">
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"></div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">


            <h3>Conditions d'utilisation de Bwè Manjé pour le site web et les applications</h3>
            <p>
                Cette page (en association avec les documents auxquels elle fait référence) présente
                les conditions d'utilisation qui vous permettent d'utiliser notre site web
                <a href="https://bwemanje.app">bwemanje.app</a> (notre "Site") ou les applications que nous mettons à disposition par
                le biais d'une boutique d'applications ou autre (notre "Service"), que ce soit comme
                hôte ou comme utilisateur inscrit. Veuillez lire attentivement ces conditions
                d'utilisation avant de commencer à utiliser notre Site ou notre Service. En accédant
                à notre Site ou en utilisant notre Service, vous indiquez que vous acceptez ces
                conditions d'utilisation et que vous vous engagez à les respecter. Si vous n'êtes
                pas d'accord avec ces conditions d'utilisation, n'accédez pas à notre Site ou
                n'utilisez pas notre Service.
            </p>

            <h4>1. A propos de nous</h4>
            <p>
                L'application BwèManjé et le site internet https://bwemanje.app est édité par
                Frédéric Barthéléry, entrepreneur individuel, inscrit au Registre du Commerce et des
                Sociétés sous le numéro 894 537 620.<br/>
                Adresse: 9 rue Anse Belune – 97220 La Trinité.<br/>
                Numéro de téléphone: 0610944265<br/>
                E-mail: contact@bwemanje.app<br/>
                Directeur de la publication: Frédéric Barthéléry<br/>
            </p>

            <h4>2. Accès à notre site ou à nos services</h4>
            <p>
                L'accès à notre Site et à notre Service est autorisé de manière temporaire et nous
                nous réservons le droit de supprimer ou de modifier sans préavis l'accès à notre
                Site ou à notre Service (voir ci-dessous). Nous ne sommes pas responsables si, pour
                quelque motif que ce soit, notre Site ou notre Service n'est pas disponible à un
                moment donné ou pendant une période donnée. Le cas échéant, nous pouvons limiter
                l'accès à certaines parties de notre Site ou de notre Service ou l’accès à
                l'ensemble de notre Site ou Service aux utilisateurs inscrits. Vous êtes responsable
                de la préservation de la confidentialité de vos informations de connexion et des
                activités de votre compte. Si vous avez des inquiétudes au sujet de vos informations
                de connexion ou si vous pensez qu'elles ont été piratées, prenez immédiatement
                contact avec contact@bwemanje.app pour nous en informer. Nous pouvons désactiver
                votre compte à tout moment.
            </p>

            <h4>3. Utilisation acceptable</h4>
            <p>
                Vous ne pouvez utiliser notre Service qu'à des fins légales. Vous ne pouvez utiliser
                notre Site ou notre Service d'aucune manière qui contrevienne à une loi ou
                réglementation locale, nationale ou internationale applicable ni pour envoyer,
                recevoir sciemment, télécharger, utiliser ou réutiliser des éléments qui ne sont pas
                conformes à nos normes en matière de contenu figurant dans la clause 5 ci-dessous.
                Vous vous engagez également à ne pas accéder sans autorisation à, endommager ou
                perturber une partie de notre Site ou de notre Service ou un réseau ou un matériel
                utilisé dans la fourniture de notre Service.
            </p>

            <h4>4. Fonctions interactives de notre site</h4>
            <p>
                Nous pouvons le cas échéant offrir des fonctions qui vous permettent d'interagir par
                le biais de notre Site ou de notre Service, comme des groupes de discussion. En
                règle générale, nous ne modérons aucun Service interactif que nous fournissons, bien
                que nous puissions supprimer un contenu qui ne respecterait pas ces conditions
                d'utilisation, conformément aux dispositions de la section 6. Si nous décidons de
                modérer un Service interactif, nous le mentionnons clairement avant que vous
                utilisiez le Service et vous offrons normalement un moyen de prendre contact avec
                l'animateur en cas de doute ou de difficulté.
            </p>

            <h4>5. Normes en matière de contenu</h4>
            <p>
                Ces normes en matière de contenu s'appliquent à tous les éléments par lesquels vous
                contribuez à notre Service (les "Contributions") et aux éventuels Services
                interactifs qui y sont associés. Vous devez respecter l'esprit et la lettre des
                normes suivantes. Les normes s'appliquent à chaque partie des Contributions et à
                leur globalité. Les Contributions doivent être précises (lorsqu'elles exposent des
                faits), être sincères (lorsqu'elles exposent des convictions) et respecter la
                législation applicable en France et dans le pays d'où elles sont émises. Les
                Contributions ne doivent pas :
                <ul>
            <li>
                contenir des éléments diffamatoires pour une personne, obscènes, injurieux, haineux
                ou incendiaires, promouvoir des scènes explicites sur le plan sexuel ou promouvoir
                la violence ou promouvoir la discrimination sur la base de la race, du sexe, de la
                religion, de la nationalité, du handicap, de l'orientation sexuelle ou de l'âge ;
            </li><li>
                enfreindre des droits d’auteur, droits sur des bases de données ou marques de
                commerce d'un tiers ;
            </li><li>
                être susceptibles de tromper un tiers ou être en infraction avec une obligation
                légale vis-à-vis d'un tiers, comme une obligation contractuelle ou une obligation de
                discrétion ou promouvoir une activité illégale ;
            </li><li>
                être menaçantes, porter atteinte à ou violer l'intimité d'un tiers, provoquer une
                gêne, un dérangement ou une anxiété inutile ou être susceptibles de harceler,
                perturber, embarrasser, alarmer ou gêner un tiers ;
            </li><li>
                être utilisées pour se faire passer pour quelqu'un d'autre ou usurper votre identité
                ou tromper sur votre affiliation avec une personne ou donner l'impression qu'elles
                émanent de nous, si tel n'est pas le cas ou
            </li><li>
                préconiser, promouvoir ou aider à un acte illégal comme (à titre d'exemple
                uniquement) une violation de droit d’auteur ou un piratage d'ordinateur.
                </li>
            </ul>
            </p>

            <h4>6. Suspension et résiliation</h4>
            <p>
                Le non-respect de la section 3 (Utilisation acceptable) et/ou 5 (Normes en matière
                de contenu) des présentes conditions d'utilisation constitue une violation
                déterminante des conditions d'utilisation et peut nous amener à prendre tout ou
                partie des mesures suivantes :
            <ul><li>
                suppression immédiate, temporaire ou permanente de votre droit d'utiliser notre
                Service ;
            </li><li>
                suppression immédiate, temporaire ou permanente des contributions ou éléments
                téléchargés par vous sur notre Service ;
            </li><li>
                envoi d'un avertissement à votre encontre ;
            </li><li>
                action en justice contre vous, comprenant une procédure en vue du remboursement de
                tous les coûts (dont, de manière non exhaustive, les coûts administratifs et les
                frais de justice raisonnables) entraînés par l'infraction ;
            </li><li>
                communication de ces informations aux autorités chargées de l'application des lois
                si nous l'estimons légitimement nécessaire.
            </li></ul>
            <p>
                Les réactions décrites dans cette clause ne sont pas exhaustives et nous pouvons
                prendre toutes les autres mesures que nous jugeons légitimement adaptées. Le nom du
                responsable de la publication et les motifs de retrait apparaitront sur le Site.
            </p>

            <h4>7. Droits de propriété intellectuelle</h4>
            <p>
                Nous sommes le propriétaire ou le détenteur de licence de tous les droits de
                propriété intellectuelle de notre Site et de notre Service et des éléments publiés
                sur ce Site (à l'exception de vos contributions). Ces travaux sont protégés par les
                lois et traités sur le droit d'auteur dans le monde entier. Tous ces droits sont
                réservés. Vous n'avez pas le droit de copier, reproduire, republier, télécharger,
                envoyer, diffuser, transmettre, communiquer au public ou utiliser un contenu de
                notre Site de quelque manière que ce soit, sauf pour votre usage personnel sans
                caractère commercial.
            </p>

            <h4>8. Confiance dans les informations affichées sur le site</h4>
            <p>
                Les commentaires et autres éléments affichés sur notre Service ne sont pas destinés
                à être considérés comme des conseils auxquels on peut faire confiance. Nous
                déclinons par conséquent toute responsabilité au titre de la confiance accordée à
                ces éléments par les visiteurs de notre Service ou par quiconque susceptible d'être
                informé de son contenu.
            </p>

            <h4>9. Actualisations régulières de notre site et de notre service</h4>
            <p>
                Nous souhaitons mettre régulièrement à jour notre Site et notre Service et pouvons
                en modifier le contenu à tout moment. En cas de nécessité, nous pouvons suspendre
                l'accès à notre Site et à notre Service ou les fermer pour une durée indéterminée.
                Les éléments de notre Site ou notre Service peuvent être dépassés à un moment donné
                et nous ne sommes en aucun cas tenus d'actualiser ces éléments.
            </p>

            <h4>10. Notre responsabilité</h4>
            <p>
                Nous avons préparé très soigneusement notre Site et notre Service. Cependant, nous
                ne sommes pas responsables des éventuelles erreurs ou omissions en rapport avec
                ledit contenu et des éventuels problèmes techniques que vous pouvez rencontrer avec
                notre Site ou notre Service. Si nous sommes informés d'inexactitudes sur notre Site
                ou dans notre Service, nous essaierons de les corriger dès que cela sera
                raisonnablement possible. Dans les limites autorisées par la loi, nous excluons
                toute responsabilité (contractuelle, au titre de la négligence ou autre) dans une
                perte ou un dommage que vous ou un tiers pourriez subir en liaison avec notre Site,
                notre Service et tout Site web lié à notre Site et les éléments affichés sur le
                Site. Cela ne concerne pas notre responsabilité en matière de décès ou de blessures
                corporelles dû(dues) à notre négligence ni notre responsabilité en matière de
                déclaration frauduleuse ou de déclaration inexacte sur un sujet fondamental ni une
                autre responsabilité qui ne peut pas être exclue ou limitée selon la législation
                applicable.
            </p>

            <h4>11. Informations relatives à vous et à vos visites sur notre site et à l'utilisation de notre service</h4>
            <p>
                Nous collectons certaines données à votre sujet dans le cadre de votre utilisation
                de notre Service. Ce point est décrit de manière plus détaillée dans notre politique
                de respect de la vie privée.
            </p>

            <h4>12. Liens de notre site</h4>
            <p>
                Lorsque notre Site contient des liens vers d'autres sites et ressources fournis par
                des tiers, ces liens sont donnés à titre d'information uniquement. Nous ne
                contrôlons pas le contenu de ces Sites et ressources et déclinons toute
                responsabilité liée à ce contenu ou en matière de perte ou dommage susceptible de
                découler de votre utilisation de ce contenu.
            </p>

            <h4>13. Compétence et législation applicable</h4>
            <p>
                Les tribunaux français ont compétence pour toutes les plaintes découlant de ou liées
                à une consultation de notre Site ou à une utilisation de nos Services. Les présentes
                conditions d'utilisation et tout conflit ou plainte découlant de ou lié(e) à ces
                conditions d'utilisation ou leur objet ou formation (dont les conflits ou plaintes
                non contractuel(le)s) doivent être régis par et interprétés conformément au droit
                français.
            </p>

            <h4>14. Modifications</h4>
            <p>
                Nous pouvons réviser les présentes conditions d'utilisation à tout moment en
                modifiant cette page. Vous êtes censé vérifier cette page de temps à autre pour
                prendre connaissance des modifications que nous avons apportées car elles sont
                contraignantes pour vous.
            </p>

            <h4>15. Vos préoccupations</h4>
            <p>
                Si vous avez des préoccupations au sujet de données qui figurent dans notre Service,
                veuillez prendre contact avec contact@bwemanje.app
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>



            <h3>Conditions générales de prestation de service de Bwè Manjé</h3>

            <p>
                Cette page (avec les documents auxquels elle fait référence) présente les conditions
                auxquelles nos restaurants partenaires vous fournissent leurs repas (les "Repas")
                figurant sur notre site internet et nos applications ("Service").
            </p><p>
                Ces conditions s’appliqueront quel que soit le mode d’accès à la plateforme et
                quelle que soit la version du Site internet (ceci incluant notamment tout site
                internet mobile, application mobile, site internet affilié ou site joint, site
                miroir ou dupliqué qui pourrait être développé).
            </p><p>
                Merci de lire attentivement ces conditions avant de commander des Repas via nos
                services.
            </p><p>
                En utilisant notre service et en passant une commande, vous acceptez d'être lié sans
                réserve par les présentes conditions et par notre politique de conditions
                d'utilisation.
            </p><p>
                Si vous avez des questions au sujet de ces conditions, veuillez nous contacter à
                l’adresse contact@bwemanje.app avant de passer commande.
            </p><p>
                Si vous n'acceptez pas intégralement ces conditions, n'utilisez pas notre Service.
            </p>

            <h4>1. A propos de nous</h4>
            <p>
                L'application BwèManjé et le site internet https://bwemanje.app est édité par
                Frédéric Barthéléry, entrepreneur individuel, inscrit au Registre du Commerce et des
                Sociétés sous le numéro 894 537 620.<br/>
                Adresse: 9 rue Anse Belune – 97220 La Trinité.<br/>
                Numéro de téléphone: 0610944265<br/>
                E-mail: contact@bwemanje.app<br/>
                Directeur de la publication: Frédéric Barthéléry<br/>
            </p>

            <h4>2. Objectif</h4>
            <p>
                Notre Service a pour but la fourniture d'un service simple et pratique en vous
                mettant en relation avec nos restaurants partenaires (« Restaurants Partenaires »)
                autorisés à proposer leurs menus et en vous permettant de leurs passer une commande
                de Repas ou d'y reserver une table.
            </p><p>
                Bwè Manjé agit en qualité d’agent pour le compte du Restaurant Partenaire qui propose
                des Repas, et prend les commandes et les reservations que vous y passez.
            </p><p>
                Le contrat pour la fourniture et l’achat des Repas est conclu entre Vous et le
                Restaurant auprès duquel Vous passez une Commande.
            </p>


            <h4>3. Votre compte</h4>
            <p>
                Avant de pouvoir passer des commandes de Repas à l'aide de notre Application, vous
                devez ouvrir un compte Bwè Manjé.
            </p><p>
                La création d'un compte Bwè Manjé est soumise aux conditions d’éligibilité
                cumulatives suivantes. En tant qu’utilisateur :
                <ul><li>
                vous avez au moins 18 ans
                </li><li>
                vous disposez de la capacité juridique requise pour vous soumettre à des obligations
                juridiques contraignantes
                </li><li>
                les commandes que vous effectuerez via notre service, pour vous ou pour toute autre
                personne pour laquelle vous êtes légalement autorisé à agir, seront légitimes
                </li><li>
                les réservations que vous effectuerez via notre service, pour vous ou pour toute
                autre personne pour laquelle vous êtes légalement autorisé à agir, seront légitimes
            </ul><p>
                Lorsque vous ouvrez un compte, vous pouvez créer un mot de passe ou utiliser une
                autre méthode de connexion sécurisée, et vous devrez peut-être aussi fournir des
                informations sur votre carte de crédit. Vous devez garder secret tout mot de passe
                que vous créez, ou toute autre méthode de connexion sécurisée, et empêcher d'autres
                personnes d'accéder à votre compte de courrier électronique ou à votre téléphone
                portable. Si une autre personne utilise ces méthodes pour accéder à votre compte,
                vous serez responsable du paiement des Repas qu'elle commande et nous ne sommes pas
                responsables des autres pertes que vous subirez, sauf si la personne qui utilise
                votre mot de passe l'a obtenu parce que nous avons manqué à notre obligation de le
                conserver de manière sécurisée.
            </p><p>
                Vous avez la possibilité de clôturer votre compte à tout moment. Pour cela, vous
                devez envoyer une demande de clôture de compte par email à : contact@bwemanje.app
            </p><p>
                Nous pouvons suspendre votre accès à votre compte, ou le fermer définitivement, si
                nous pensons que votre compte a été utilisé par quelqu'un d'autre. Nous pouvons
                également fermer votre compte si nous estimons que vous abusez de notre service (par
                exemple, en demandant des remboursements ou des crédits auxquels nous estimons que
                vous n'avez pas droit, en déposant des plaintes déraisonnables et répétées, en
                maltraitant notre personnel, ou pour toute autre raison valable).
            </p><p>
                Si nous fermons définitivement votre compte, nous vous rembourserons tout crédit
                restant que vous aurez valablement obtenu auprès de notre équipe de service
                clientèle ou sur l'Application suite à un problème avec une commande, en appliquant
                un crédit sur votre carte de crédit enregistrée, ou si cela n'est pas possible pour
                une raison quelconque, par le biais d'un virement bancaire en utilisant les
                coordonnées bancaires (à condition que vous nous les ayez fournies).
            </p>

            <h4>4. Disponibilité des services</h4>
            <p>
                Le Service Bwè Manjé est disponible pour tous ses Restaurants Partenaires.
                Les heures d'ouverture varient en fonction des habitudes commerciales locales et de
                la disponibilité de nos Restaurants Partenaires.
            </p><p>
                Bwè Manjé se réserve le droit de suspendre, supprimer ou de modifier tout ou partie
                des services offerts sur le Site ou les applications mobiles Bwè Manjé sans préavis.
                Bwè Manjé se réserve de plus le droit de suspendre l’accès au Site Bwè Manjé, à tous
                les, ou à une partie des, Utilisateurs pour maintenance, en cas d’urgence
                (cyberattaque, etc.) ou pour toute autre raison, et ce à tout moment.
            </p>

            <h4>5. Commandes</h4>
            <p>
                Chaque commande passée par le biais de notre Service sera transmise au Restaurant
                Partenaire pour obtenir une confirmation de sa part. La commande est définitive une
                fois qu’elle a été acceptée par le Restaurant Partenaire. Nous vous envoyons alors,
                pour le compte du Restaurant Partenaire, un e-mail vous remerciant de votre commande
                et confirmant que celle-ci a été reçue et acceptée par le Restaurant Partenaire
                (l'"E-mail de confirmation").
            </p><p>
                En passant une commande auprès de nos Services, vous agissez pour votre seul compte.
                Le contrat de fourniture de tout Repas que vous commandez par notre intermédiaire
                est conclu entre vous et le Restaurant Partenaire et n’est conclu qu’à compter de
                l’envoi de l'E-mail de confirmation. Nous vous remercions de vérifier que l'adresse
                électronique que vous nous avez donnée est correcte car c'est elle qui nous
                permettra de communiquer avec vous au sujet de votre commande.
            </p><p>
                Nous contrôlons très attentivement nos Restaurants Partenaires et il est extrêmement
                important pour nous qu'ils respectent nos normes et nous aident à maintenir notre
                réputation. Vous pouvez nous adresser vos commentaires éventuels au sujet de nos
                Restaurants Partenaires ou des Repas par e-mail, par téléphone ou en nous contactant
                au sujet de votre commande depuis notre application mobile. A travers le processus
                de prise de commande, vous acceptez expressément l’application des présentes
                conditions générales de prestations de service.
            </p>


            <h4>6. Repas</h4>
            <p>
                Tous les Repas sont proposés sous réserve de leur disponibilité. Votre Restaurant
                Partenaire peut proposer une solution de remplacement pour un Repas que nous ne
                pouvons vous fournir.
            </p><p>
                Vous reconnaissez et acceptez que si vous avez une allergie ou une intolérance
                alimentaire spécifique, il est de votre responsabilité de revoir les informations
                concernant les allergènes fournies sur nos services et de passer commande uniquement
                si vous êtes certain que le menu convient à vos besoins spécifiques.
            </p><p>
                Bwè Manjé ne peut pas garantir que les Repas vendus par nos Restaurants Partenaires
                sont sans allergène.
            </p>


            <h4>7. Vente d'alcool</h4>
            <p>
                Les boissons alcoolisées ne peuvent être vendues qu'aux personnes de plus de 18 ans
                et une preuve peut être exigée lors de la recuperation de votre commande.
                Le Restaurant Partenaire pourra refuser de fournir de l'alcool à toute personne qui
                semble avoir moins de 18 ans ou qui est ou semble sous l'influence de l'alcool ou de
                médicaments. En passant une commande qui contient de l'alcool, vous confirmez que
                vous avez au moins 18 ans. Toute violation du présent article engage votre
                responsabilité personnelle exclusive.
            </p><p>
                L’abus d’alcool est dangereux pour la santé. Sachez consommer et apprécier avec
                modération. Par le seul fait de passer commande via nos services,
                vous renoncez expressément au bénéfice de l’article 1587 du Code civil qui prévoit
                que la conclusion de la vente de vin ne devient définitive qu’après dégustation et
                agrément de l’acheteur.
            </p>


            <h4>8. Disponibilité et livraison</h4>
            <p>
                Notre objectif est de garantir que vous obteniez le meilleur service de livraison
                possible. Malheureusement, les choses ne se déroulent pas toujours comme prévu et
                des aléas comme les conditions de circulation ou les conditions météorologiques
                peuvent parfois nous empêcher d'atteindre nos objectifs, ou ceux de nos Restaurants
                Partenaires, à cet égard. Nous faisons tout notre possible pour garantir la
                livraison de votre Repas dans le délai indiqué dans l'e-mail et sur la page Web. Le
                délai de livraison de votre commande est établi en fonction du nombre de commandes
                et des conditions auxquelles est soumis le Restaurant Partenaire à ce moment.
            </p>


            <h4>9. Annulation</h4>
            <p>
                Vous avez le droit d'annuler une commande dans un délai raisonnable et avant que le
                Restaurant Partenaire n’ait commencé à préparer la commande ("Commande Lancée"). Les
                clients peuvent annuler une commande en prenant contact avec le Restaurant
                Partenaire. Bwè Manjé et le Restaurant Partenaire peuvent annuler une commande et
                vous en informent dans ce cas. Les commandes annulées conformément à cette clause ne
                vous sont pas facturées. Tout règlement effectué avant l'annulation d'une commande
                par Bwè Manjé ou un Restaurant Partenaire est généralement remboursé par le même
                moyen que celui que vous avez utilisé pour payer votre commande. Chaque commande
                annulée après être devenue une Commande Lancée vous est facturée. Bwè Manjé seul
                établit si une commande est ou non une Commande Lancée. Vous ne disposez pas de
                droit de rétractation, en vertu des dispositions de l’article L121-21-8 alinéa 4 du
                Code de la consommation, les Repas, objets du contrat étant des denrées périssables.
            </p>


            <h4>10. Prix, règlement et offres promotionnelles</h4>
            <p>
                Les prix incluent la TVA. Vous confirmez que vous utilisez notre Service pour un
                usage personnel et non commercial. Bwè Manjé peut parfois pratiquer une tarification
                dynamique, ce qui signifie que les prix des Articles peuvent changer pendant que
                vous naviguez. Les prix peuvent également changer à tout moment à la discrétion des
                Restaurants Partenaires. Nous nous réservons le droit de facturer des Frais de
                Service, qui peuvent être modifiés, pour la fourniture de nos Services. Ces Frais de
                service peuvent être variables. Vous serez informé des Frais de Service et des taxes
                applicables avant l'achat sur la page de paiement de notre Application. Aucun
                changement n'affectera les commandes confirmées existantes, sauf en cas d'erreur de
                prix évidente. Les modifications de prix n'affecteront pas non plus les commandes en
                cours de traitement et figurant dans votre panier, à condition que vous complétiez
                la commande. S'il y a une erreur de prix évidente, nous vous en informerons dès que
                possible et vous aurez le choix de confirmer la commande au prix initial ou
                d'annuler la commande sans frais et avec un remboursement complet de toute somme
                déjà payée.
            </p><p>
                Le prix total de votre commande sera indiqué sur la page de paiement de notre
                Application, y compris les prix des Articles ainsi que les Frais de Service et les
                taxes applicables.
            </p><p>
                Le paiement de tous les Articles peut être effectué sur notre Application par carte
                de crédit ou de débit, ou par tout autre mode de paiement proposé par Bwè Manjé. Une
                fois que votre commande a été confirmée, votre carte de crédit ou de débit sera
                autorisée et le montant total sera indiqué pour le paiement. Le paiement est
                effectué directement à Bwè Manjé agissant pour le compte du Restaurant Partenaire
                uniquement.
            </p><p>
                Nous sommes autorisés par nos Restaurants Partenaires à accepter des paiements en
                leur nom et le paiement du prix de tout Article nous permettra de remplir votre
                obligation de payer le prix au Restaurant Partenaire. Dans certains cas, vous pouvez
                également effectuer votre paiement en espèces directement au Restaurant Partenaire
                en payant le Restaurant Partenaire au moment de la récuperation de votre Repas.
            </p><p>
                Lorsque le paiement en espèces est possible, cela sera clairement indiqué sur notre
                Application avant que vous ne passiez votre commande.
            </p><p>
                Les Restaurants Partenaires proposent parfois des offres promotionnelles par le
                biais de notre Application. Celles-ci sont visibles lorsque vous regardez le menu
                d'un Restaurant Partenaire. Ces offres sont à la discrétion des Restaurants
                Partenaires. Sauf si les conditions de l'offre indiquent une période fixe ou
                minimale pendant laquelle une offre sera disponible, elle peut être retirée à tout
                moment, sauf si vous avez déjà passé une commande sur la base de l'offre et que nous
                avons envoyé l'Avis de Confirmation.
            </p>


            <h4>11. Notre responsabilité</h4>
            <p>
                Dans les limites autorisées par la loi, Bwè Manjé fournit son Service et son contenu
                "en l'état" et "en fonction de la disponibilité". Selon les dispositions ci-dessous,
                ni Bwè Manjé ni quelque Restaurant partenaire que ce soit n'ont de responsabilité
                vis-à-vis de vous en ce qui concerne des pertes directes, indirectes, particulières
                ou des dommages d'ordre contractuel, dus à un préjudice (dont la négligence) ou par
                ailleurs découlant de votre utilisation ou de votre incapacité à utiliser notre
                Service. Si la responsabilité de Bwè Manjé ou du Restaurant partenaire est engagée
                vis-à-vis de vous, notre responsabilité totale cumulée est limitée au prix d'achat
                des Repas que vous avez payés dans votre commande. Cela ne comprend pas et ne limite
                en aucune manière la responsabilité de Bwè Manjé ou d'un restaurant partenaire sur un
                sujet pour lequel il serait illégal que nous excluions ou tentions d'exclure ou
                qu'il exclue ou tente d'exclure notre ou sa responsabilité, en particulier, la
                responsabilité dans un décès ou des blessures corporelles dû (dues) à la négligence
                ou à une fraude ou à une déclaration frauduleuse. Le contrat de vente est conclu
                entre vous et le Restaurant. Le Restaurant partenaire que vous choisissez est un
                commerçant indépendant, distinct de notre société et sur lequel nous n’avons aucun
                contrôle. En conséquence, en dehors des strictes conditions légales, notre
                responsabilité ne saurait être engagée à ce titre. Le Restaurant partenaire,
                vendeur, est tenu des défauts de conformité du bien au contrat dans les conditions
                de l’article L 211-4 du Code de la consommation et des défauts de la chose vendue en
                cas de vices cachés (article 1641 Code civil) et de la garantie légale de conformité
                (article L211-4 et suivant Code de la consommation).
            </p>


            <h4>12. Evenements indépendants de notre volonté</h4>
            <p>
                Aucune partie n'est responsable vis-à-vis de l'autre d'un retard ou d'une absence
                d'exécution de ses obligations aux termes du présent contrat si ledit retard ou
                ladite absence est indépendant(e) de sa volonté dont, de manière non exhaustive, les
                événements suivants : catastrophe naturelle, disposition gouvernementale, guerre,
                incendie, inondation, explosion ou mouvements populaires.
            </p>


            <h4>13. Rénonciation</h4>
            <p>
                Ni vous, ni Bwè Manjé, ni le Restaurant partenaire n'êtes responsables vis-à-vis des
                autres parties d'un retard ou d'une absence d'exécution de ses obligations aux
                termes du présent contrat si ledit retard ou ladite absence est indépendant(e) de sa
                volonté dont, de manière non exhaustive, les événements suivants : catastrophe
                naturelle, disposition gouvernementale, guerre, incendie, inondation, explosion ou
                mouvements populaires.
            </p>


            <h4>14. Disjonction</h4>
            <p>
                Si une disposition du présent contrat est jugée illégale ou inapplicable, la force
                exécutoire intégrale des autres dispositions demeure.
            </p>


            <h4>15. Contrat indivisible</h4>
            <p>
                Les présentes conditions contiennent l'intégralité du contrat entre les parties
                relatif à son objet et remplacent l'ensemble des contrats et accords antérieurs
                entre les parties relatifs à cet objet.
            </p>


            <h4>16. Notre droit de modifier ces conditions</h4>
            <p>
                Bwè Manjé peut réviser les présentes conditions à tout moment en modifiant cette
                page. Vous êtes censé vérifier cette page de temps à autre pour prendre connaissance
                des modifications que nous avons apportées car elles sont contraignantes pour vous.
            </p>


            <h4>17. Droit et compétence</h4>
            <p>
                Les tribunaux français ont compétence pour toutes les plaintes découlant de ou liées
                à une utilisation de nos Services. Les présentes conditions d'utilisation et tout
                conflit ou plainte découlant de ou lié(e) à ces conditions d'utilisation ou leur
                objet ou formation (dont les conflits ou plaintes non contractuel(le)s) doivent être
                régis par et interprétés conformément au droit français. Conformément aux
                dispositions du Code de la consommation, il vous est possible de recourir à un mode
                alternatif de règlement des litiges ou à une procédure de médiation conventionnelle.
                La recherche d’une telle solution amiable interrompt les délais pour agir. A défaut,
                la juridiction compétente sera déterminée par les règles de droit commun.
            </p>




     </div>
            <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3"></div>
        </div>
</div>
    """.trimIndent()
}
