/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.remember
import js.material.MDCCircularProgress
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.Element


private val MdcCircularProgressHtml = """
        <div ref="root" class="mdc-circular-progress mdc-circular-progress--indeterminate" style="width:48px;height:48px;" role="progressbar" aria-label="Loading Progress Bar" aria-valuemin="0" aria-valuemax="1">
          <div class="mdc-circular-progress__determinate-container">
            <svg class="mdc-circular-progress__determinate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <circle class="mdc-circular-progress__determinate-track" cx="24" cy="24" r="18" stroke-width="4"/>
              <circle class="mdc-circular-progress__determinate-circle" cx="24" cy="24" r="18" stroke-dasharray="113.097" stroke-dashoffset="113.097" stroke-width="4"/>
            </svg>
          </div>
          <div class="mdc-circular-progress__indeterminate-container">
            <div class="mdc-circular-progress__spinner-layer">
              <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left">
                <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="18" stroke-dasharray="113.097" stroke-dashoffset="56.549" stroke-width="4"/>
                </svg>
              </div>
              <div class="mdc-circular-progress__gap-patch">
                <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="18" stroke-dasharray="113.097" stroke-dashoffset="56.549" stroke-width="3.2"/>
                </svg>
              </div>
              <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right">
                <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="18" stroke-dasharray="113.097" stroke-dashoffset="56.549" stroke-width="4"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
    """.trimIndent()


class MdcCircularProgressState {
    private var mdcCircularProgress: MDCCircularProgress? = null
    fun open() {
        mdcCircularProgress?.open()
    }

    fun close() {
        mdcCircularProgress?.close()
    }

    fun dispose() {
        mdcCircularProgress = null
    }

    internal fun mount(element: Element) {
        mdcCircularProgress = MDCCircularProgress(element)
    }
}

@Composable
fun rememberMdcCircularProgressState() = remember { MdcCircularProgressState() }

/**
 *  Composable version of Material MDCCircularProgress
 *  https://github.com/material-components/material-components-web/tree/master/packages/mdc-circular-progress
 */
@Composable
fun MdcCircularProgress(
    state: MdcCircularProgressState = rememberMdcCircularProgressState(),
) {
    Div {
        DisposableEffect(state) {
            scopeElement.innerHTML = MdcCircularProgressHtml
            state.mount(scopeElement)
            onDispose {
                state.dispose()
            }
        }
    }
}

