/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.locales

internal val enMessages = js(
"""
{
  "global" : {
    "title" : "Bwè Manjé Customer Portal",
    "footer": "Made with ❤️ and ☕️"
  },
  "signin": {
    "title": "Sign in",
    "loading": "Loading \u2026"
  },
  "portal": {
    "loading": "Loading \u2026"
  },
  "subscribe": {
    "title": "Join us",
    "lbl_pricing": "Choose pricing plan",
    "btn_subscribe": "Subscribe",
    "pricing_option_month": "{amount}{currency} per month"
  },
  "active_subscriptions": {
    "title": "My subscription",
    "manage_title": "View invoices, update subscription & payment methods",
    "payouts_title": "View payouts",
    "subscription_trial_info": "Your free trial will end on",
    "subscription_trial_ended_info": "Your free trial ended on",
    "subscription_info": "Your plan of {price}{currency} is active",
    "subscription_next_payment": "Your plan renews on "
  },
  "payment_onboarding": {
    "title": "Complete your profile",
    "business_heading": "Tell us about your business",
    "label_customer_type_individual": "Individual",
    "label_customer_type_company": "Company",
    "label_first_name": "First name",
    "label_last_name": "Last name",
    "label_company_name": "Company name",
    "label_company_siren": "SIREN number",
    "payment_title": "Payment information",
    "stripe_collect_info_description": "We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click <strong>Save and continue</strong> to set up your payments on Stripe.",
    "stripe_connect_payment_description": "We use Stripe to make sure you get paid on time and keep your personal and bank details secure.",
    "btn_save_and_continue": "Save and continue",
    "btn_set_up_payments": "Set up payments",
  },
  "stripe": {
    "billing_portal": "Billing",
    "payouts_portal": "Payouts"
  },
  "appbar" : {
    "disconnect": "Sign out"
  }
}
"""
)

internal val enDateTimeFormats = js(
"""
{
  "short": {
    "year": "numeric",
    "month": "short",
    "day": "numeric"
  },
  "long": {
    "year": "numeric",
    "month": "long",
    "day": "numeric"
  }
}
"""
)
