/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.geekorum.rdv.bwemanje.customerportal.components.MdcCircularProgress
import com.geekorum.rdv.bwemanje.customerportal.compose.setContent
import com.geekorum.rdv.bwemanje.customerportal.compose.stringResource
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.viewModel
import firebase.app.FirebaseApp
import firebase.auth
import js.firebaseui.AuthUI
import js.firebaseui.Config
import js.vue.ComponentOptions
import js.vue.inject
import js.vue.props
import js.vue.ref
import js.vuerouter.useRouter
import kotlinx.js.jso
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Section
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Element
import kotlin.js.json

class SignInViewModel(
    val authUi: AuthUI,
) {
    val authUiConfig: Config = jso {
//        signInOptions = arrayOf(GoogleAuthProvider.PROVIDER_ID)
        signInOptions = arrayOf("google.com")
        signInSuccessUrl = "/"
        tosUrl = "/terms-of-service"
        privacyPolicyUrl = "/privacy-policy"
    }

}

val SignInPage: ComponentOptions = jso {
    template = """
        <section ref="el">
        </section>
    """.trimIndent()

    props("signedInPath")

    setup = {  props, ctx ->
        val el = ref<Element>()
        val firebaseApp = checkNotNull(inject<FirebaseApp>("firebaseApp"))
        val router = useRouter()
        val signedInPath = props.signedInPath
        setContent({ el.value!! } ){
            val viewModel: SignInViewModel = viewModel {
                SignInViewModel(
                    authUi = AuthUI.getInstance()?.apply { reset() }
                        ?: AuthUI(firebaseApp.auth())
                )
            }

            SignInSection(viewModel.authUi, viewModel.authUiConfig,
                onSignInSuccess = {
                    // redirect to signedInPath
                    router.push(signedInPath)
                },
            )
        }

        json("el" to el)
    }
}

@Composable
private fun SignInSection(
    authUi: AuthUI,
    config: Config,
    onSignInSuccess: (String?) -> Unit,
) {
    var isLoading by remember { mutableStateOf(true)}

    val actualConfig = remember(config) {
        config.apply {
            callbacks = jso {
                signInSuccessWithAuthResult = { authResult, successUrl ->
                    onSignInSuccess(successUrl)
                    false
                }
                uiShown = { isLoading = false }
            }
        }
    }

    H2({
        style {
            textAlign("center")
        }
    }) {
        Text(stringResource("signin.title"))
    }
    Section(
        { id("firebaseui-auth-container") }
    ) {
        if (isLoading) {
            Div({ classes("loading-container") }) {
                MdcCircularProgress()
                Span({ classes() }) { Text(stringResource("signin.loading")) }
            }
        }
        DisposableEffect(Unit) {
            authUi.start(scopeElement, actualConfig)
            onDispose { }
        }
    }
}
