/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.geekorum.rdv.bwemanje.customerportal.FIREBASE_FUNCTIONS_REGION_STRIPE_EXT
import com.geekorum.rdv.bwemanje.customerportal.compose.stringResource
import firebase.app.FirebaseApp
import firebase.functions
import firebase.functions.httpsCallable
import firebase.functions.invoke
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text
import kotlin.js.json

const val FUNCTION_STRIPE_CREATE_PORTAL_LINK = "ext-firestore-stripe-payments-createPortalLink"
const val FUNCTION_STRIPE_CREATE_ACCOUNT_EXPRESS_LINK = "createStripeAccountExpressDashboardLink"


/**
 * Link to customer billing portal where customer can manage its subscriptions
 * Only works if user is authenticated in Firebase
 */
@Composable
fun StripeBillingPortalLink(firebaseApp: FirebaseApp, onRedirectStart: () -> Unit) {
    val coroutineScope = rememberCoroutineScope()
    StripeBillingPortalLink(onClick = {
        onRedirectStart()
        coroutineScope.launch {
            val functions = firebaseApp.functions(FIREBASE_FUNCTIONS_REGION_STRIPE_EXT)
            val returnUrl = window.location.href
            val result =
                httpsCallable<dynamic, dynamic>(functions, FUNCTION_STRIPE_CREATE_PORTAL_LINK)(
                    json(
                        "returnUrl" to returnUrl
                    )
                ).await()
            window.location.assign(result.data.url)
        }
    })
}

@Composable
fun StripeBillingPortalLink(onClick: () -> Unit) {
    A(href = "",
        attrs = {
            onClick {
                it.preventDefault()
                onClick()
            }
        }) {
        Text(stringResource("stripe.billing_portal"))
    }
}


/**
 * Link to Stripe express account dashboard where customer can manage its bank transfer, and various payments reports
 * The link opens a new tab
 * Only works if user is authenticated in Firebase
 */
@Composable
fun StripeExpressDashboardLink(
    firebaseApp: FirebaseApp,
    onRedirectStart: () -> Unit,
    onRedirectEnd: () -> Unit,
) {
    val coroutineScope = rememberCoroutineScope()
    StripeExpressDashboardLink(onClick = {
        onRedirectStart()
        val newWindow = window.open("", "stripe_express") ?: window
        coroutineScope.launch {
            val functions = firebaseApp.functions(FIREBASE_FUNCTIONS_REGION_STRIPE_EXT)
            val result =
                httpsCallable<dynamic, dynamic>(functions, FUNCTION_STRIPE_CREATE_ACCOUNT_EXPRESS_LINK)().await()
            newWindow.location.replace(result.data.url)
            onRedirectEnd()
        }
    })
}

@Composable
fun StripeExpressDashboardLink(
    onClick: () -> Unit,
) {
    A(href = "",
        attrs = {
            onClick {
                it.preventDefault()
                onClick()
            }
        }) {
        Text(stringResource("stripe.payouts_portal"))
    }
}
