/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.locales

internal val frMessages = js(
"""
{
  "global" : {
    "title" : "Bwè Manjé Portail Restaurateur",
    "footer": "Réalisé avec ❤️ et ☕️"
  },
  "signin": {
    "title": "Connectez vous",
    "loading": "Chargement \u2026"
  },
  "portal": {
    "loading": "Chargement \u2026"
  },
  "subscribe": {
    "title": "Rejoignez nous",
    "lbl_pricing": "Choisir un tarif",
    "btn_subscribe": "Souscrire",
    "pricing_option_month": "{amount}{currency} par mois"
  },
  "active_subscriptions": {
    "title": "Mon abonnement",
    "manage_title": "Gérer votre abonnement",
    "payouts_title": "Gérer vos virements",
    "subscription_trial_info": "Votre période d'essai se terminera le",
    "subscription_trial_ended_info": "Votre période d'essai s'est terminée le",
    "subscription_info": "Votre abonnement de {price}{currency} est actif",
    "subscription_next_payment": "Il sera renouvellé le "
  },
    "payment_onboarding": {
    "title": "Completer votre profil",
    "business_heading": "Parlez nous de votre entreprise",
    "label_customer_type_individual": "Personne individuelle",
    "label_customer_type_company": "Société",
    "label_first_name": "Prénom",
    "label_last_name": "Nom",
    "label_company_name": "Nom de la société",
    "label_company_siren": "Identifiant SIREN",
    "payment_title": "Informations de paiement",
    "stripe_collect_info_description": "Nous utilisons Stripe pour nous assurer que vous soyez payé à temps et pour protéger vos informations bancaires. Cliquez sur <strong>Enregistrer et continuer</strong> pour mettre en place vos paiements sur Stripe.",
    "stripe_connect_payment_description": "Nous utilisons Stripe pour nous assurer que vous soyez payé à temps et pour protéger vos informations bancaires.",
    "btn_save_and_continue": "Enregistrer et continuer",
    "btn_set_up_payments": "Configurer les paiements",
  },
  "stripe": {
    "billing_portal": "Facturation",
    "payouts_portal": "Virements",
  },
  "appbar" : {
    "disconnect": "Déconnexion"
  }
}
"""
)

internal val frDateTimeFormats = js(
"""
{
  "short": {
    "year": "numeric",
    "month": "short",
    "day": "numeric"
  },
  "long": {
    "year": "numeric",
    "month": "long",
    "day": "numeric"
  }
}
"""
)
