/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel

import androidx.compose.runtime.staticCompositionLocalOf

class ViewModelStore {
    private val store = mutableMapOf<String, Any>()

    fun put(key: String, vm: Any) {
        val old = store.put(key, vm)
        if (old != null) {
            // old.clear()
        }
    }

    fun get(key: String): Any? = store[key]

    fun clear() {
        store.values.forEach {
//            it.clear()
        }
        store.clear()
    }
}

interface ViewModelStoreOwner {
    val viewModelStore: ViewModelStore
}

val LocalViewModelStoreOwner = staticCompositionLocalOf<ViewModelStoreOwner?> { null }
