/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel

import kotlin.reflect.KClass

private val DEFAULT_KEY = "com.geekorum.viewmodel.DEFAULT_KEY"

class ViewModelProvider(
    private val viewModelStore: ViewModelStore,
    private val factory: Factory
) {

    interface Factory {
        /**
         * Creates a new instance of the given `Class`.
         *
         * @param modelClass a `Class` whose instance is requested
         * @param <T>        The type parameter for the ViewModel.
         * @return a newly created ViewModel
        </T> */
        fun <T : Any> create(key: String, modelClass: KClass<T>): T
    }

    fun <T: Any> get(modelClass: KClass<T>) : T {
        val name = modelClass.simpleName
        val key = "$DEFAULT_KEY:$name"
        return get(key, modelClass)
    }

    fun <T : Any> get(key: String, modelClass: KClass<T>): T {
        var vm = viewModelStore.get(key)
        if (modelClass.isInstance(vm)) {
            @Suppress("UNCHECKED_CAST")
            return vm as T
        }
        vm = factory.create(key, modelClass)
        viewModelStore.put(key, vm)
        return vm
    }
}
