/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages.portalpage

import androidx.compose.runtime.NoLiveLiterals
import firebase.auth.User
import firebase.firestore.Firestore
import firebase.firestore.SetOptions
import firebase.firestore.collection
import firebase.firestore.doc
import firebase.firestore.get
import firebase.firestore.onSnapshot
import firebase.firestore.set
import firebase.functions.Functions
import firebase.functions.httpsCallable
import firebase.functions.invoke
import js.vuerouter.Router
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.async
import kotlinx.coroutines.await
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.js.jso
import kotlin.js.json

data class PaymentOnBoardingUiState(
    val customerType: String? = null,
    val firstName: String = "",
    val lastName: String = "",
    val companyName: String = "",
    val companySIREN: String = "",
    val stripeAccountId: String = "",
    val hasSendBusinessInformation: Boolean = false,
    val stripeChargesEnabled: Boolean = false,
    val stripeDetailsSubmitted: Boolean = false,
) {
    val onBoardingState: PaymentOnBoardingViewModel.OnBoardingState
        get() {
            return when {
                hasSendBusinessInformation && stripeAccountId.isNotBlank() &&
                        stripeChargesEnabled && stripeDetailsSubmitted
                -> PaymentOnBoardingViewModel.OnBoardingState.Complete

                hasSendBusinessInformation && stripeAccountId.isNotBlank()
                -> PaymentOnBoardingViewModel.OnBoardingState.ConnectPayment

                else -> PaymentOnBoardingViewModel.OnBoardingState.CollectInfo
            }
        }

}

class PaymentOnBoardingViewModel(
    private val firebaseUser: User,
    private val firestore: Firestore,
    private val functions: Functions,
    private val router: Router,
    private val viewModelScope: CoroutineScope,
) {

    enum class OnBoardingState { CollectInfo, ConnectPayment, Complete }

    private val _uiState = MutableStateFlow(PaymentOnBoardingUiState())
    val state = _uiState.asStateFlow()

    init {
        loadCustomerData()
    }

    private fun loadCustomerData() = viewModelScope.launch {
        val customerDocSnapshot = firestore.collection<dynamic>("customers")
            .doc(firebaseUser.uid)
            .get()
            .await()
        val customer = customerDocSnapshot.data()
        _uiState.value = PaymentOnBoardingUiState(
            firstName = customer.firstName.unsafeCast<String?>() ?: "",
            lastName = customer.lastName.unsafeCast<String?>() ?: "",
            companyName = customer.name.unsafeCast<String?>() ?: "",
            companySIREN = customer.SIREN.unsafeCast<String?>() ?: "",
            customerType = customer.businessType.unsafeCast<String?>(),
            stripeAccountId = customer.stripeAccountId.unsafeCast<String?>() ?: "",
            stripeChargesEnabled = customer.stripeChargesEnabled.unsafeCast<Boolean?>() ?: false,
            stripeDetailsSubmitted = customer.stripeDetailsSubmitted.unsafeCast<Boolean?>() ?: false,
            hasSendBusinessInformation = customer.hasSendBusinessInformation.unsafeCast<Boolean?>() ?: false
        )
    }

    @NoLiveLiterals // avoid compile issue with compose
    fun saveBusinessInformation() = viewModelScope.launch {
        val email = firebaseUser.providerData.firstNotNullOfOrNull { it.email } ?: ""
        val docData = with(state.value) {
            jso<dynamic> {
                when (customerType) {
                    "individual" -> {
                        this["firstName"] = firstName.trim()
                        this["lastName"] = lastName.trim()
                        this["name"] = "$firstName $lastName".trim()
                        this["SIREN"] = ""
                    }

                    "company" -> {
                        this["name"] = companyName.trim()
                        this["SIREN"] = companySIREN.trim()
                        this["firstName"] = ""
                        this["lastName"] = ""
                    }
                }
                this["businessType"] = customerType
                this["email"] = email.trim()
                this["hasSendBusinessInformation"] = true
            }
        }
        val setOptions = jso<SetOptions> {
            merge = true
        }

        @Suppress("UnsafeCastFromDynamic")
        firestore.collection<dynamic>("customers")
            .doc(firebaseUser.uid)
            .set(docData, setOptions)
            .await()
    }

    suspend fun getStripeOnBoardingUrlAsync() = viewModelScope.async {
        awaitCustomerWithStripeAccountId()
        //TODO define the url correctly
        val returnUrl = window.location.origin + router.resolve("/").asDynamic().href
        val refreshUrl = window.location.href
        val result =
            httpsCallable<dynamic, dynamic>(functions, "createStripeAccountOnBoardingLink")(
                json(
                    "return_url" to returnUrl,
                    "refresh_url" to refreshUrl
                )
            ).await()
        return@async result.data.url
    }

    /**
     * When Customer data hasSendBusinessInformation field is set to true, a cloud function will create a
     * stripe account and set the stripeAccountId field. This method observe the customer data and return
     * the stripeAccountId when it is set
     */
    private suspend fun awaitCustomerWithStripeAccountId() : String {
        val docRef = firestore.collection<dynamic>("customers")
            .doc(firebaseUser.uid)

        // we need to wait for the sessionId and/or error to be attached by the cloud function
        val resultFlow = callbackFlow {
            docRef.onSnapshot(onNext = { snapshot ->
                val data = snapshot.data()
                val accountId = data.stripeAccountId.unsafeCast<String?>()
                trySend(accountId)
            })
            awaitClose()
        }
        return resultFlow.filterNotNull().first()
    }

    fun setCustomerType(type: String) {
        _uiState.update { it.copy(customerType = type) }
    }

    fun setFirstName(value: String) {
        _uiState.update { it.copy(firstName = value) }
    }

    fun setLastName(value: String) {
        _uiState.update { it.copy(lastName = value) }
    }

    fun setCompanyName(value: String) {
        _uiState.update { it.copy(companyName = value) }
    }

    fun setCompanySiren(value: String) {
        _uiState.update { it.copy(companySIREN = value) }
    }
}