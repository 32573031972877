/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.components

import firebase.app.FirebaseApp
import firebase.auth
import js.`this`
import js.material.MDCMenu
import js.material.MDCRipple
import js.thisAs
import js.vue.ComponentOptions
import js.vue.inject
import js.vuerouter.Router
import kotlinx.js.jso
import org.w3c.dom.Element
import org.w3c.dom.events.EventListener

val AppHeader = jso<ComponentOptions> {
    template = """
        <header class="mdc-top-app-bar">
          <div class="mdc-top-app-bar__row">
            <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
              <router-link tag="button" to="/" class="mdc-icon-button material-icons mdc-top-app-bar__navigation-icon mdc-top-app-bar__navigation-icon--unbounded" aria-label="Go to home">home</router-link>
              <span class="mdc-top-app-bar__title" v-t="'global.title'"></span>
            </section>
            <section v-if="firebaseUser.value"
                class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" >
                <div class="mdc-menu-surface--anchor">
                <div class="userMenuAnchorSurface mdc-ripple-surface ">
                    <img class="userPhoto" :src="firebaseUser.value.photoURL"
                        @click="userMenu.open = !userMenu.open" />
                </div>

                <div class="mdc-menu mdc-menu-surface">
                  <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
                    <li class="mdc-list-item" role="menuitem">
                      <span class="mdc-list-item__ripple"></span>
                      <span class="mdc-list-item__text" v-t="'appbar.disconnect'"></span>
                    </li>
                  </ul>
                </div>
                </div>
            </section>
          </div>
        </header>
    """.trimIndent()

    inject("firebaseApp",
        "firebaseUser" // reference to firebase User
    )

    data = { HeaderData()}

    beforeUpdate = {
        val data = thisAs<HeaderData>()
        data.userMenuEl?.removeEventListener("MDCMenu:selected", data.selectedEventListener)
    }

    updated = {
        val element = `this`.`$el`.unsafeCast<Element>()
        val data = thisAs<HeaderData>()
        data.userMenuEl = element.querySelector(".mdc-menu")
        data.userMenu = data.userMenuEl?.let { MDCMenu(it) }
        val firebaseApp = checkNotNull(`this`.firebaseApp.unsafeCast<FirebaseApp>())
        val router = `this`.`$router`.unsafeCast<Router>()
        data.selectedEventListener = EventListener {
            val detail = it.asDynamic().detail
            if (detail.index == 0) {
                val auth = firebaseApp.auth()
                auth.signOut()
                router.push("/signin")
            }
        }
        data.userMenuEl?.addEventListener("MDCMenu:selected", data.selectedEventListener)
        element.querySelector("userMenuAnchorSurface")?.let {
            MDCRipple(it)
        }
    }

    beforeUnmount = {
        val data = thisAs<HeaderData>()
        data.userMenuEl?.removeEventListener("MDCMenu:selected", data.selectedEventListener)
    }

}

// had to do this for some issue with js(IR) mangling
external interface HeaderData {
    var userMenu: MDCMenu?
    var userMenuEl: Element?
    var selectedEventListener: EventListener?
}

fun HeaderData() = jso<HeaderData> {
    userMenu = null
    userMenuEl = null
    selectedEventListener = null
}
