/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel

import androidx.compose.runtime.Composable
import kotlin.reflect.KClass

@Composable
inline fun <reified VM: Any> viewModel(
    viewModelStoreOwner: ViewModelStoreOwner = checkNotNull(LocalViewModelStoreOwner.current) {
        "No ViewModelStoreOwner was provided via LocalViewModelStoreOwner"
    },
    key: String? = null,
): VM {
    val failingFactory = object : ViewModelProvider.Factory {
        override fun <T : Any> create(key: String, modelClass: KClass<T>): T {
            error("A previous instance of ViewModel was not found for $key." +
                    "You need to specify a ViewModel factory")
        }
    }
    return viewModel(VM::class, viewModelStoreOwner, key, failingFactory)
}

@Composable
inline fun <reified VM: Any> viewModel(
    viewModelStoreOwner: ViewModelStoreOwner = checkNotNull(LocalViewModelStoreOwner.current) {
        "No ViewModelStoreOwner was provided via LocalViewModelStoreOwner"
    },
    key: String? = null,
    factory: ViewModelProvider.Factory,
): VM = viewModel(VM::class, viewModelStoreOwner, key, factory)

@Composable
inline fun <reified VM: Any> viewModel(
    viewModelStoreOwner: ViewModelStoreOwner = checkNotNull(LocalViewModelStoreOwner.current) {
        "No ViewModelStoreOwner was provided via LocalViewModelStoreOwner"
    },
    key: String? = null,
    crossinline factory: (String) -> VM,
): VM {
    val wrappedFactory = object : ViewModelProvider.Factory {
        override fun <T : Any> create(key: String, modelClass: KClass<T>): T {
            return factory(key) as T
        }
    }

    return viewModel(VM::class, viewModelStoreOwner, key, wrappedFactory)
}

@Composable
fun <T: Any> viewModel(
    modelClass: KClass<T>,
    viewModelStoreOwner: ViewModelStoreOwner = checkNotNull(LocalViewModelStoreOwner.current) {
        "No ViewModelStoreOwner was provided via LocalViewModelStoreOwner"
    },
    key: String? = null,
    factory: (String) -> T,
): T {
    val wrappedFactory = object : ViewModelProvider.Factory {
        override fun <T : Any> create(key: String, modelClass: KClass<T>): T {
            return factory(key) as T
        }
    }
    return viewModelStoreOwner.get(modelClass, key, wrappedFactory)
}

@Composable
fun <T: Any> viewModel(
    modelClass: KClass<T>,
    viewModelStoreOwner: ViewModelStoreOwner = checkNotNull(LocalViewModelStoreOwner.current) {
        "No ViewModelStoreOwner was provided via LocalViewModelStoreOwner"
    },
    key: String? = null,
    factory: ViewModelProvider.Factory,
): T {
    return viewModelStoreOwner.get(modelClass, key, factory)
}

private fun <T: Any> ViewModelStoreOwner.get(
    modelClass: KClass<T>,
    key: String?,
    factory: ViewModelProvider.Factory,
): T {
    val provider = ViewModelProvider(viewModelStore, factory)
    return if (key != null) {
        provider.get(key, modelClass)
    } else provider.get(modelClass)
}
