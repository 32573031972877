/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages.portalpage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.geekorum.rdv.bwemanje.customerportal.components.MdcCircularProgress
import com.geekorum.rdv.bwemanje.customerportal.compose.LocalVueComponentScope
import com.geekorum.rdv.bwemanje.customerportal.compose.setContent
import com.geekorum.rdv.bwemanje.customerportal.compose.stringResource
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.viewModel
import firebase.app.FirebaseApp
import firebase.auth.User
import firebase.firestore
import firebase.functions
import js.material.MDCFormField
import js.material.MDCRadio
import js.vue.ComponentOptions
import js.vue.Ref
import js.vue.inject
import js.vue.ref
import js.vue.unref
import js.vuerouter.useRouter
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.js.jso
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.name
import org.jetbrains.compose.web.attributes.onSubmit
import org.jetbrains.compose.web.attributes.pattern
import org.jetbrains.compose.web.attributes.required
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioGroupScope
import org.jetbrains.compose.web.dom.RadioInput
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.jetbrains.compose.web.events.SyntheticInputEvent
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement
import org.w3c.xhr.FormData
import kotlin.js.json

val PaymentOnBoardingPage: ComponentOptions = jso {
    template = """
        <section ref="el">
        </section>
    """.trimIndent()

    setup = { props, ctx ->
        val el = ref<Element>()
        val firebaseApp = checkNotNull(inject<FirebaseApp>("firebaseApp"))
        val firebaseUser = unref(checkNotNull(inject<Ref<User>>("firebaseUser")))
        val router = useRouter()

        setContent({ el.value!! }) {
            val componentCoroutineScope = checkNotNull(LocalVueComponentScope.current)
            val viewModel: PaymentOnBoardingViewModel = viewModel {
                PaymentOnBoardingViewModel(
                    firestore = firebaseApp.firestore(),
                    firebaseUser = firebaseUser,
                    functions = firebaseApp.functions(),
                    router = router,
                    viewModelScope = componentCoroutineScope,
                )
            }
            PaymentOnBoardingPage(viewModel, onOnBoardingComplete = {
                router.push("/")
            })
        }

        json("el" to el)
    }
}


@Composable
fun PaymentOnBoardingPage(viewModel: PaymentOnBoardingViewModel, onOnBoardingComplete: () -> Unit) {
    val uiState by viewModel.state.collectAsState()
    val coroutineScope = rememberCoroutineScope()
    var isNavigatingToStripe by remember { mutableStateOf(false) }
    LaunchedEffect(uiState.onBoardingState) {
        if (uiState.onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.Complete) {
            onOnBoardingComplete()
        }
    }

    PaymentOnBoardingPage(
        onBoardingState = uiState.onBoardingState,
        customerType = uiState.customerType,
        firstName = uiState.firstName,
        lastName = uiState.lastName,
        companyName = uiState.companyName,
        companySIREN = uiState.companySIREN,
        isNavigatingToStripe = isNavigatingToStripe,
        onCustomerTypeChange = viewModel::setCustomerType,
        onFirstNameChange = viewModel::setFirstName,
        onLastNameChange = viewModel::setLastName,
        onCompanyNameChange = viewModel::setCompanyName,
        onCompanySirenChange = viewModel::setCompanySiren,
        onSubmit = {
            coroutineScope.launch {
                try {
                    isNavigatingToStripe = true
                    viewModel.saveBusinessInformation().join()
                    val url = viewModel.getStripeOnBoardingUrlAsync().await()
                    window.location.assign(url)
                } catch (e: Exception) {
                    isNavigatingToStripe = false
                }
            }
        }
    )
}

@Composable
fun PaymentOnBoardingPage(
    onBoardingState: PaymentOnBoardingViewModel.OnBoardingState,
    customerType: String?,
    firstName: String,
    lastName: String,
    companyName: String,
    companySIREN: String,
    isNavigatingToStripe: Boolean,
    onCustomerTypeChange: (String) -> Unit,
    onFirstNameChange: (String) -> Unit,
    onLastNameChange: (String) -> Unit,
    onCompanyNameChange: (String) -> Unit,
    onCompanySirenChange: (String) -> Unit,
    onSubmit: (FormData) -> Unit
) {
    PaymentOnBoardingPageLayout {
        if (isNavigatingToStripe) {
            PaymentOnBoardLoading()
        } else {
            H2 { Text(stringResource("payment_onboarding.title")) }

            PaymentOnBoardingForm(
                onBoardingState = onBoardingState,
                customerType = customerType,
                firstName = firstName,
                lastName = lastName,
                companyName = companyName,
                companySIREN = companySIREN,
                onSubmit = onSubmit,
                onCustomerTypeChange = onCustomerTypeChange,
                onFirstNameChange = onFirstNameChange,
                onLastNameChange = onLastNameChange,
                onCompanyNameChange = onCompanyNameChange,
                onCompanySirenChange = onCompanySirenChange
            )
        }
    }
}

@Composable
private fun PaymentOnBoardLoading() {
    Div({ classes("loading-container") }) {
        Div {
            MdcCircularProgress()
            Span {
                Text(stringResource("portal.loading"))
            }
        }
    }
}


@Composable
private fun PaymentOnBoardingForm(
    onBoardingState: PaymentOnBoardingViewModel.OnBoardingState,
    customerType: String?,
    firstName: String,
    lastName: String,
    companyName: String,
    companySIREN: String,
    onSubmit: (FormData) -> Unit,
    onCustomerTypeChange: (String) -> Unit,
    onFirstNameChange: (String) -> Unit,
    onLastNameChange: (String) -> Unit,
    onCompanyNameChange: (String) -> Unit,
    onCompanySirenChange: (String) -> Unit,
) {
    Form(attrs = {
        classes("business-info-form")
        onSubmit {
            it.preventDefault()
            val formData = FormData(it.target as HTMLFormElement)
            onSubmit(formData)
        }
    }
    ) {
        if (onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.CollectInfo) {
            P { Text(stringResource("payment_onboarding.business_heading")) }
            CollectInformationSection(
                customerType = customerType,
                firstName = firstName,
                lastName = lastName,
                companyName = companyName,
                companySIREN = companySIREN,
                onCustomerTypeChange = onCustomerTypeChange,
                onFirstNameChange = onFirstNameChange,
                onLastNameChange = onLastNameChange,
                onCompanyNameChange = onCompanyNameChange,
                onCompanySirenChange = onCompanySirenChange
            )
        }

        PaymentSection(onBoardingState)

        SubmitSection(onBoardingState)
    }
}

@Composable
private fun SubmitSection(
    onBoardingState: PaymentOnBoardingViewModel.OnBoardingState,
) {
    Div({ classes("submit-zone")}) {
        Button({
            id("save")
            classes("mdc-fab", "mdc-fab--extended")
            type(ButtonType.Submit)
        }) {
            Div({
                classes("mdc-fab__ripple")
            })

            Span({ classes("mdc-fab__label") }) {
                if (onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.CollectInfo) {
                    Text(stringResource("payment_onboarding.btn_save_and_continue"))
                }
                if (onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.ConnectPayment) {
                    Text(stringResource("payment_onboarding.btn_set_up_payments"))
                }
            }
        }
    }
}



@Composable
private fun PaymentSection(onBoardingState: PaymentOnBoardingViewModel.OnBoardingState) {
    H4 { Text(stringResource("payment_onboarding.payment_title")) }
    StripeInformationPanel(onBoardingState)
}

@Composable
private fun StripeInformationPanel(onBoardingState: PaymentOnBoardingViewModel.OnBoardingState) {
    Div({ classes("mdc-card", "stripe-information") }) {
        Div({
            classes("stripe-information-content")
        }) {
            if (onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.CollectInfo) {
                P {
                    val paragraphHtml =
                        stringResource("payment_onboarding.stripe_collect_info_description")
                    DisposableEffect(Unit) {
                        scopeElement.innerHTML = paragraphHtml
                        onDispose { }
                    }
                }
            }
            if (onBoardingState == PaymentOnBoardingViewModel.OnBoardingState.ConnectPayment) {
                Text(
                    stringResource("payment_onboarding.stripe_connect_payment_description")
                )
            }
        }
    }
}

@Composable
private fun CollectInformationSection(
    customerType: String?,
    firstName: String,
    lastName: String,
    companyName: String,
    companySIREN: String,
    onCustomerTypeChange: (String) -> Unit,
    onFirstNameChange: (String) -> Unit,
    onLastNameChange: (String) -> Unit,
    onCompanyNameChange: (String) -> Unit,
    onCompanySirenChange: (String) -> Unit,
) {
    CustomerTypeRadioGroup(customerType ?: "", onValueChange = onCustomerTypeChange)
    Div {
        if (customerType == "individual") {
            MdcTextfield(
                id = "firstName",
                label = stringResource("payment_onboarding.label_first_name"),
                value = firstName, required = true,
                onValueChange = onFirstNameChange,
            )
            Br()
            MdcTextfield(
                id = "lastName",
                label = stringResource("payment_onboarding.label_last_name"),
                value = lastName, required = true,
                onValueChange = onLastNameChange,
            )

            // empty but keep to avoid shifting elements when switchin to company panel
            Div({ classes("mdc-text-field-helper-line") }) {
                Div({
                    classes("mdc-text-field-helper-text")
                    attr("aria-hidden", "true")
                })
            }
        } else if (customerType == "company") {
            MdcTextfield(
                id = "companyName",
                label = stringResource("payment_onboarding.label_company_name"),
                value = companyName, required = true,
                onValueChange = onCompanyNameChange,
            )
            Br()

            MdcTextfield(
                id = "companySIREN",
                label = stringResource("payment_onboarding.label_company_siren"),
                value = companySIREN,
                required = true,
                pattern = "[0-9]{9}",
                onValueChange = onCompanySirenChange
            )

            Div({ classes("mdc-text-field-helper-line") }) {
                Div({
                    classes("mdc-text-field-helper-text")
                    attr("aria-hidden", "true")
                }) {
                    Text("123456789")
                }
            }
        }
    }
}

@Composable
private fun MdcTextfield(
    id: String? = null,
    label: String = "",
    value: String = "",
    required: Boolean = false,
    pattern: String? = null,
    onValueChange: (String) -> Unit = {},
) {
    Label(attrs = {
        if (id != null) {
            id(id)
        }
        classes("mdc-text-field", "mdc-text-field--filled")
        if (value.isNotEmpty()) {
            classes("mdc-text-field--label-floating")
        }
    }) {
        Span({ classes("mdc-text-field__ripple") })
        Span({
            classes("mdc-floating-label")
            id("label-$id")
            if (value.isNotEmpty()) {
                classes("mdc-floating-label--float-above")
            }
        }) {
            Text(label)
        }
        TextInput(value = value,
            attrs = {
                classes("mdc-text-field__input")
                attr("aria-labelledby", "label-$id")
                if (required) {
                    required()
                }
                if (pattern != null) {
                    pattern(pattern)
                }
                onInput { onValueChange(it.value) }
            })
        Span({ classes("mdc-line-ripple") })
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun CustomerTypeRadioGroup(
    checkedValue: String,
    onValueChange: (String) -> Unit
) {
    RadioGroup(checkedValue) {
        Div({
            classes("mdc-form-field")
            id("form-field-customer-type-individual")
        }) {
            var formField by remember { mutableStateOf<MDCFormField?>(null) }
            DisposableEffect(Unit) {
                formField = MDCFormField(scopeElement)
                onDispose { formField = null }
            }

            val radioInputState = rememberMdcRadioInputState()
            LaunchedEffect(formField, radioInputState.mdcRadioInput) {
                formField?.input = radioInputState.mdcRadioInput
            }

            MdcRadioInput(
                id = "customer-type-individual",
                inputId = "radio-1",
                value = "individual",
                state = radioInputState,
                onInputEvent = {
                    onValueChange("individual")
                }
            )
        }
        Label("radio-1") {
            Text(stringResource("payment_onboarding.label_customer_type_individual"))
        }

        Div({
            classes("mdc-form-field")
            id("form-field-customer-type-company")
        }) {
            var formField by remember { mutableStateOf<MDCFormField?>(null) }
            DisposableEffect(Unit) {
                formField = MDCFormField(scopeElement)
                onDispose { formField = null }
            }

            val radioInputState = rememberMdcRadioInputState()
            LaunchedEffect(formField, radioInputState.mdcRadioInput) {
                formField?.input = radioInputState.mdcRadioInput
            }

            MdcRadioInput(id = "customer-type-company", inputId = "radio-2", value = "company",
                state = radioInputState,
                onInputEvent = {
                    onValueChange("company")
                }
            )
        }
        Label("radio-2") {
            Text(stringResource("payment_onboarding.label_customer_type_company"))
        }
    }
}

private class MdcRadioInputState{
    var mdcRadioInput: MDCRadio? = null
        private set
    fun mount(element: Element) {
        mdcRadioInput = MDCRadio(element)
    }

    fun dispose() {
        mdcRadioInput = null
    }
}

@Composable
private fun rememberMdcRadioInputState() = remember { MdcRadioInputState()}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun RadioGroupScope<String>.MdcRadioInput(
    id: String, inputId: String, value: String,
    state: MdcRadioInputState,
    onInputEvent: (SyntheticInputEvent<Boolean, HTMLInputElement>) -> Unit
) {
    Div({
        classes("mdc-radio")
        id(id)
    }) {
        DisposableEffect(Unit) {
            state.mount(scopeElement)
            onDispose {
                state.dispose()
            }
        }

        RadioInput(id = inputId, value = value,
            attrs = {
                classes("mdc-radio__native-control")
                name("radios")
                onInput(onInputEvent)
            })
        Div({ classes("mdc-radio__background") }) {
            Div({ classes("mdc-radio__outer-circle") })
            Div({ classes("mdc-radio__inner-circle") })
        }
        Div({ classes("mdc-radio__ripple") })
    }
}


@Composable
private fun PaymentOnBoardingPageLayout(content: ContentBuilder<HTMLDivElement>?) {
    Div({ classes("mdc-layout-grid", "payment-onboarding") }) {
        Div({ classes("mdc-layout-grid__inner") }) {
            Div({ classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-3") })
            Div(
                { classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-6") },
                content = content
            )
            Div({ classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-3") })
        }
    }
}
