/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package js.vue

import kotlinx.js.jso
import kotlin.js.json

@Suppress("DEPRECATION")
fun DataOptionsApi.setComputed(key: String, compute: Function<*>) {
    computed = computed ?: jso()
    computed[key] = compute
}

@Suppress("DEPRECATION")
fun DataOptionsApi.setMethod(key: String, method: Function<*>) {
    methods = methods ?: jso()
    methods[key] = method
}

@Suppress("DEPRECATION")
fun DataOptionsApi.props(vararg prop: String) {
    props = prop
}

@Suppress("DEPRECATION")
fun DataOptionsApi.emits(vararg emit: String) {
    emits = emit
}

@Suppress("DEPRECATION")
fun AssetsOptionsApi.registerLocalComponents(vararg pairs: Pair<String, dynamic>) {
    components = json(*pairs)
}

@Suppress("DEPRECATION")
fun CompositionOptionsApis.provides(vararg pairs: Pair<String, Any>) {
    provide = json(*pairs)
}

/**
 * provides properties using a function that can access the component using javascript [this][js.this]
 * The returned object properties will be available for injection into descendants.
 */
@Suppress("DEPRECATION")
fun CompositionOptionsApis.provides(fn: Function<Any>) {
    provide = fn
}

@Suppress("DEPRECATION")
fun CompositionOptionsApis.inject(vararg objects: String) {
    inject = objects
}

