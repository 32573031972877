/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages.portalpage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.geekorum.rdv.bwemanje.customerportal.DEPLOYMENT_ENV
import com.geekorum.rdv.bwemanje.customerportal.FIREBASE_FUNCTIONS_REGION_STRIPE_EXT
import com.geekorum.rdv.bwemanje.customerportal.components.MdcCircularProgress
import com.geekorum.rdv.bwemanje.customerportal.components.StripeBillingPortalLink
import com.geekorum.rdv.bwemanje.customerportal.components.StripeExpressDashboardLink
import com.geekorum.rdv.bwemanje.customerportal.components.rememberMdcCircularProgressState
import com.geekorum.rdv.bwemanje.customerportal.compose.LocalVueComponentScope
import com.geekorum.rdv.bwemanje.customerportal.compose.dateFormat
import com.geekorum.rdv.bwemanje.customerportal.compose.setContent
import com.geekorum.rdv.bwemanje.customerportal.compose.stringResource
import com.geekorum.rdv.bwemanje.customerportal.compose.viewmodel.viewModel
import com.geekorum.rdv.bwemanje.customerportal.pages.portalpage.PortalPageViewModel.Subscription
import firebase.app.FirebaseApp
import firebase.auth.User
import firebase.firestore
import firebase.functions
import js.vue.ComponentOptions
import js.vue.Ref
import js.vue.inject
import js.vue.ref
import js.vue.unref
import js.vuerouter.useRouter
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.toKotlinInstant
import kotlinx.js.jso
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.name
import org.jetbrains.compose.web.attributes.onSubmit
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Section
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLFormElement
import org.w3c.xhr.FormData
import kotlin.js.json

val PortalPage: ComponentOptions = jso {
    template = """
        <section ref="el">
        </section>
    """.trimIndent()

    setup = { props, ctx ->
        val el = ref<Element>()
        val firebaseApp = checkNotNull(inject<FirebaseApp>("firebaseApp"))
        val firebaseUser = unref(checkNotNull(inject<Ref<User>>("firebaseUser")))
        val router = useRouter()

        setContent({ el.value!! }) {
            val componentCoroutineScope = checkNotNull(LocalVueComponentScope.current)
            val viewModel: PortalPageViewModel = viewModel {
                PortalPageViewModel(
                    firestore = firebaseApp.firestore(),
                    functions = firebaseApp.functions(),
                    stripeExtFunctions = firebaseApp.functions(FIREBASE_FUNCTIONS_REGION_STRIPE_EXT),
                    viewModelScope = componentCoroutineScope,
                    showTestCards = DEPLOYMENT_ENV != "production"
                )
            }
            viewModel.setUserId(firebaseUser.uid)
            PortalPage(viewModel,
                navigateToOnboardingPayment = { router.replace("/onboarding_payment") }
            )

        }

        json("el" to el)
    }
}


@Composable
private fun PortalPage(
    viewModel: PortalPageViewModel,
    navigateToOnboardingPayment: () -> Unit
) {
    val uiState by viewModel.uiState.collectAsState()
    if (uiState.hasActiveSubscription && !uiState.hasCompletePaymentOnBoarding) {
        LaunchedEffect(Unit) {
            navigateToOnboardingPayment()
        }
    }
    PortalPage(
        isLoading = uiState.isLoading,
        showTestCards = uiState.showTestCards,
        subscriptions = uiState.subscriptions,
        products = uiState.subscriptionPlans,
        onSubmit = {
            viewModel.subscribe(it,
                onCheckoutSessionFailed = {
                    console.error("Unable to create checkout session")
                    window.alert("Unable to create checkout session")
                },
            )
        },
        onBillingPortalLinkClick = viewModel::openStripeBillingPortal,
        onExpressDashboardLinkClick = viewModel::openStripeExpressDashboard,
    )
}

@Composable
private fun PortalPage(
    isLoading: Boolean,
    showTestCards: Boolean,
    subscriptions: List<Subscription>,
    products: List<Product>,
    onSubmit: (FormData) -> Unit,
    onBillingPortalLinkClick: () -> Unit,
    onExpressDashboardLinkClick: () -> Unit,
) {
    PortalPageLayout {
        if (isLoading) {
            PortalLoading()
        } else if (subscriptions.isNotEmpty()) {
            ActiveSubscriptionsPanel(
                subscriptions,
                onBillingPortalLinkClick = onBillingPortalLinkClick,
                onExpressDashboardLinkClick = onExpressDashboardLinkClick
            )
        } else {
            SubscribeProductPanel(showTestCards, products, onSubmit)
        }
    }
}

@Composable
internal fun ActiveSubscriptionsPanel(
    subscriptions: List<Subscription>,
    onBillingPortalLinkClick: () -> Unit,
    onExpressDashboardLinkClick: () -> Unit
) {
    H2 { Text(stringResource("active_subscriptions.title")) }
    Section({ classes("subscriptions") }) {
        for (subscription in subscriptions) {
            Div({ classes("subscription") }) {
                val now = Clock.System.now()
                val trialEnd = subscription.trialEnd?.toKotlinInstant()
                val displayTrialInfo = trialEnd != null && trialEnd > now
                if (displayTrialInfo) {
                    P({ classes("description") }) {
                        val infoText =
                            stringResource("active_subscriptions.subscription_trial_info")
                        val endDate = dateFormat(subscription.trialEnd!!, "long")
                        Text("$infoText $endDate")
                    }
                } else if (trialEnd != null) {
                    P({ classes("description") }) {
                        val infoText =
                            stringResource("active_subscriptions.subscription_trial_ended_info")
                        val endDate = dateFormat(subscription.trialEnd, "long")
                        Text("$infoText $endDate")
                    }
                }
                P({ classes("description") }) {
                    val infoText = stringResource(
                        "active_subscriptions.subscription_info",
                        namedArgs =
                        mapOf(
                            "price" to subscription.price.amount,
                            "currency" to subscription.price.currencySymbol
                        )
                    )
                    Text(infoText)
                }
                P({ classes("description") }) {
                    val infoText = stringResource("active_subscriptions.subscription_next_payment")
                    val endDate = dateFormat(subscription.currentPeriodEnd!!, "long")
                    Text("$infoText $endDate")
                }
            }
        }
    }

    StripeLinks(
        onBillingPortalLinkClick = onBillingPortalLinkClick,
        onExpressDashboardLinkClick = onExpressDashboardLinkClick
    )
}

@Composable
private fun StripeLinks(
    onBillingPortalLinkClick: () -> Unit,
    onExpressDashboardLinkClick: () -> Unit
) {
    H3 { Text(stringResource("active_subscriptions.manage_title")) }
    StripeBillingPortalLink(onBillingPortalLinkClick)
    H3 { Text(stringResource("active_subscriptions.payouts_title")) }
    StripeExpressDashboardLink(onExpressDashboardLinkClick)
}

@Composable
private fun SubscribeProductPanel(
    showTestCards: Boolean,
    products: List<Product>,
    onSubmit: (FormData) -> Unit
) {
    H2 { Text(stringResource("subscribe.title")) }
    if (showTestCards) {
        TestCardInfo()
    }
    for (plan in products) {
        Div({ classes("products") }) {
            Form(attrs = {
                classes("product-form")
                onSubmit {
                    it.preventDefault()
                    val formData = FormData(it.target as HTMLFormElement)
                    onSubmit(formData)
                }
            }) {
                ProductCard(plan)
                Input(InputType.Hidden) {
                    id("quantity")
                    name("quantity")
                    value(1)
                }
                Button({
                    id("subscribe")
                    classes("mdc-fab", "mdc-fab--extended")
                    type(ButtonType.Submit)
                }) {
                    Div({ classes("mdc-fab__ripple") })
                    Span({ classes("mdc-fab__label") }) { Text(stringResource("subscribe.btn_subscribe")) }
                }
            }
        }
    }
}

@Composable
private fun TestCardInfo() {
    Div({ classes("test-card-notice") }) {
        Text("Use any of the")
        A(href = "https://stripe.com/docs/testing#cards",
            {
                target(ATarget.Blank)
                attr("rel", "noopener noreferrer")

            }) { Text("Stripe test cards") }
        Text("for this demo, e.g.")
        Div({ classes("card-number") }) {
            repeat(4) {
                Text("4242")
                Span()
            }
        }
    }
}

@Composable
private fun PortalLoading() {
    Div({ classes("loading-container") }) {
        Div {
            val mdcCircularProgressState = rememberMdcCircularProgressState()
            MdcCircularProgress(mdcCircularProgressState)
            Span {
                Text(stringResource("portal.loading"))
            }
        }
    }
}

@Composable
private fun PortalPageLayout(content: ContentBuilder<HTMLDivElement>?) {
    Div({ classes("mdc-layout-grid") }) {
        Div({ classes("mdc-layout-grid__inner") }) {
            Div({ classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-3") })
            Div(
                { classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-6") },
                content = content
            )
            Div({ classes("mdc-layout-grid__cell", "mdc-layout-grid__cell--span-3") })
        }
    }
}
