/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package firebase.functions

import kotlin.js.Promise

operator fun <RequestData, ResponseData> HttpsCallable<RequestData, ResponseData>.invoke(data: RequestData? = null): Promise<HttpsCallableResult<ResponseData>> {
    return asDynamic()(data) as Promise<HttpsCallableResult<ResponseData>>
}

fun <RequestData, ResponseData> Functions.httpsCallable(name: String, options: HttpsCallableOptions? = null ) =
    firebase.functions.httpsCallable<RequestData, ResponseData>(this, name, options)