/*
 * Bwè Manjé is a restaurant table booking application on the Android Platform.
 *
 * Copyright (C) 2020-2023 by Frédéric-Charles Barthéléry.
 *
 * This file is part of Bwè Manjé.
 */
package com.geekorum.rdv.bwemanje.customerportal.pages.portalpage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.geekorum.rdv.bwemanje.customerportal.compose.stringResource
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.name
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun ProductCard(product: Product) {
    Div({
        classes("mdc-card", "product-card")
    }) {
        Div({
            classes("product-card__media", "mdc-card__media", "mdc-card__media--16-9")
        }) {
            Div({
                classes("mdc-card__media-content")
            }) {
                H5 {
                    Text(product.name)
                }
            }
        }

        ProductCardContent(product)
    }
}

@Composable
private fun ProductCardContent(product: Product) {
    Div({
        classes("product-card-content")
    }) {
        P({ classes("description") }) {
            Text(product.description)
        }
        P({ classes("pricing") }) {
            val prices by remember(product) {
                derivedStateOf {
                    product.prices.filter { it.active }
                        .filter { it.currency == "eur" }
                }
            }
            for (price in prices) {
                Div {
                    val namedArgs = mapOf(
                        "amount" to price.amount,
                        "currency" to price.currencySymbol
                    )
                    Text(stringResource("subscribe.pricing_option_${price.interval}", namedArgs))
                    Input(InputType.Hidden) {
                        id("price")
                        name("price")
                        value(price.id)
                    }
                }
            }
        }
    }
}
